.slick-prev-title {
    font-size: rem(35);
    line-height: 1.34;
    margin-bottom: 0;
    margin-left: rem(21);
    margin-right: rem(21);
    color: $black;
    @include media-breakpoint-down(sm) {
        font-size: 1.25rem;
        margin-left: rem(10);
        margin-right: rem(10);
    }
}

.slick-arrow {
    position: static;
    display: inline-block;
}

.container-nos-valeur {
    margin-top: rem(50);
    margin-bottom: rem(118);
    // @include media-breakpoint-up(sm) {
    //     padding-left: 30px;
    //     padding-right: 30px;
    // }
    @include media-breakpoint-down(sm) {
        margin-bottom: rem(65);
        padding-left: 0;
        padding-right: 0;
    }
    .h1 {
        text-align: center;
        color: $primary;
        line-height: 1.36;
        margin-bottom: rem(35);
        @include media-breakpoint-down(sm) {
            margin-bottom: rem(10);
        }
    }
    .nav-slick-arrow {
        position: relative;
        @include media-breakpoint-down(sm) {
            display: flex;
            align-items: center;
            margin-top: rem(10);
        }
        @include media-breakpoint-down(xs) {
            padding-left: 3%;
            padding-right: 3%;
        }
    }
}

.row-nos-valeur {
    @include media-breakpoint-down(sm) {
        margin-left: 0;
        margin-right: 0;
    }
    @include media-breakpoint-up(sm) {
        margin-left: -30px;
        margin-right: -30px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            padding-right: 1px;
        }
        .col-left,
        .col-right {
            padding-left: 30px;
            padding-right: 30px;
            width: 100%;
        }
    }
    
    
    .col-right {
        @include media-breakpoint-up(xgl) {
            padding-bottom: 50px;
            width: 30%;
            max-width: 30%;
            flex: 0 0 30%;
            order: 1;
        }
        @include media-breakpoint-down(sm) {
            padding-left: rem(37);
            padding-right: rem(37);
        }
        @include media-breakpoint-down(xs) {
            padding-left: 3%;
            padding-right: 3%;
        }
        
        position: relative;
        @include media-breakpoint-up(xgl) {
            .nav-slick-arrow {
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                text-align: center;
            }
        }
        
    }
    .col-left {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
        @include media-breakpoint-up(xgl) {
            width: 70%;
            max-width: 70%;
            flex: 0 0 70%;
            order: 2;
        }
        
    }
    .number {
        font-size: 7.5rem;
        font-family: $fontTitle;
        @include media-breakpoint-down(sm) {
            font-size: 4.375rem;
        }
        @include media-breakpoint-between(sm, xgl) {
            font-size: vw(120);
        }
    }
    h3, .h3 {
        color: $primary;
        line-height: 1.125;
        margin-bottom: rem(18);
        word-wrap: break-word;
        overflow-wrap: break-word;
        @include media-breakpoint-down(sm) {
            margin-bottom: rem(8);
        }
    }
    .p {
        color: $gray-700;
        line-height: 1.375;
        
        @include media-breakpoint-up(xgl) {
            max-width: 325px;
        }
    }
}

// @include media-breakpoint-down(sm) {
//     .sliderNumber-byOne {
//         .slick-list,
//         .slick-slide {
//             height: auto !important;
//         }
//     }
// }



.sliderNumber-byOne {
    .slick-track{
        display: flex;
    
        .slick-slide{
            display: flex;
            height: auto;
            align-items: center; //optional
            justify-content: center; //optional
            & > div {
                width: 100%;
            }
        }
    }
}