.custom-card {
    background-color: $white;
    box-shadow: 0 3px 20px rgba(0,0,0,0.16);
    padding: rem(35) rem(50); 
    @include media-breakpoint-down(sm) {
        padding: rem(20) rem(37); 
        box-shadow: 0 3px 10px rgba(0,0,0,0.16);
        max-width: 100%;
    }
    @include media-breakpoint-down(xs) {
        padding-left: 3%;
        padding-right: 3%;
    }
}

.produit-card {
    @include media-breakpoint-up(sm) {
        padding: rem(40) rem(32);
    }
    h3, .h3 {
        @include media-breakpoint-up(sm) {
            line-height: 1.125;
            margin-bottom: rem(30);
        }
    }
    .btn {
        @include media-breakpoint-up(sm) {
            margin-top: 1rem;
            float: right;
        }
        @include media-breakpoint-down(sm) {
            display: block;
            max-width: rem(260);
            margin-left: auto;
            margin-right: auto;
            margin-top: rem(20);
        }
        
    }
    @include media-breakpoint-down(xl) {
        padding: rem(20) rem(37); 
        box-shadow: 0 3px 20px rgba(0,0,0,0.16);
    }
    @include media-breakpoint-down(xs) {
        padding-left: 3%;
        padding-right: 3%;
    }
}

.container-chiffres {
    padding-bottom: rem(50);
    [class*="col-"] {
        display: flex;
    }
    .card-chiffre {
        width: 100%;
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-down(sm) {
            max-width: 250px;
        }
    }

}

.card-chiffre {
    min-height: rem(350);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: rem(40);
    margin-bottom: rem(40);
    @include media-breakpoint-down(sm) {
        min-height: 250px;
        margin-top: rem(20);
        margin-bottom: rem(20);
        box-shadow: 0 3px 20px rgba(0,0,0,0.16);
    }
    .h1 {
        line-height: 1.36;
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
            font-size: rem(45);
        }
    }
    .h3 {
        line-height: 1.125;
        margin-bottom: 0;
        @include media-breakpoint-down(sm) {
            font-size: rem(45);
        }
    }
    .p {
        margin-top: rem(12);
        font-size: rem(22);
        font-family: $fontTitle;
        line-height: 1.31;
        margin-bottom: 0;
        color: $black;
        font-weight: 700;
        max-width: 260px;
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-down(sm) {
            max-width: none;
        }

    }
}


.bandeau__card {
    @include media-breakpoint-up(xgl) {
        margin-left: auto;
        margin-right: rem(90);
    }
}

.container-bandeau {
    .pos-a-container {
        bottom: rem(25);
        @include media-breakpoint-down(lg) {
            position: static;
            padding-left: 0;
            padding-right: 0;
            .bandeau__card {
                max-width: 100%;
            }
        }
    }
    // img {
    //     width: 100%;
    //     min-height: 220px;
    //     object-fit: cover;
    // }
}


.container-bandeau {
    .pos-a-container {
        @include media-breakpoint-up(xgl) {
            padding-left: rem(48);
            padding-right: rem(48);
        }
        
    }
    .card-actualite {
        margin-left: auto;
    }
}
.card-actualite {
    @include clearfix;
    @include media-breakpoint-up(sm) {
        padding: rem(40) rem(35);
        .btn {
            float: right;
            margin-top: rem(6);
        }
    }
}


.bandeau-actualite {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: rem(75);
    .cover {
        background-position: left;
    }
    @include media-breakpoint-up(sm) {
        .bandeau__body {
            padding-top: rem(115);
            padding-bottom: rem(115);
        }
        .card-actualite {
            margin-right: 5%;
        }
    }
    @include media-breakpoint-down(sm) {
        margin-bottom: rem(40);
        .bandeau__body {
            background-image: none !important;
            img {
                min-height: 215px;
            }
        }
        .card-actualite {
            box-shadow: none;
            .btn {
                display: block;
                max-width: rem(220);
                margin-left: auto;
                margin-right: auto;
                margin-top: rem(20);
            }
        }
    }
}