.container--sliderExpoProduct {
    position: relative;
    padding-top: rem(45);
    padding-bottom: rem(45);
    @include media-breakpoint-down(lg) {
        margin-left: -24px;
        margin-right: -24px;
        padding-bottom: rem(60);
        padding-top: 0;
    }
    @include media-breakpoint-down(xs) {
        margin-left: -16px;
        margin-right: -16px;
    }
    .sliderExpoProduct--container {
        @include media-breakpoint-up(xgl) {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 33%;
        }
        
    }
    .nav-slick-arrow {
        @include media-breakpoint-up(xgl) {
            text-align: center;
            margin-top: rem(100);
        }
        
    }
}


.sliderExpoProduct--cards .produit-card {
    @include media-breakpoint-down(lg) {
        max-width: 100%;
        box-shadow: 0 6px 10px rgba(0,0,0,0.16);
    }
}


.sliderExpoProductMiel {
    
    @include media-breakpoint-down(xgl) {
        padding-bottom: rem(40);
        margin-bottom: 0 !important;
        .slick-dots {
            bottom: rem(15);
            li {
                width: rem(15);
                height: rem(15);
                button {
                    width: inherit;
                    height: inherit;
                    &::before {
                        width: inherit;
                        height: inherit;
                    }
                }
                
                margin: 0 4px;
            }
            li:not(.slick-active) button:before {
                background-color: $gray-300;
            }
            
        }
    }
    
    
    &__item {
        .produit-card {
            position: absolute;
            display: none;
        }
    }
    
}

@include media-breakpoint-up(xgl) {
    .container-carousel-miel {
        .container--sliderExpoProduct {
            padding: rem(40) 0;
            &:nth-child(odd) {
                .sliderExpoProductMiel {
                    padding-left: 25%;
                }
            }
            &:nth-child(even) {
                .sliderExpoProductMiel {
                    padding-right: 25%;
                }
                .sliderExpoProduct--container {
                    left: auto;
                    right: 0;
                }
                
            }
        }
    }
}


#filter-month {
    max-width: rem(536);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1025;
    @include media-breakpoint-down(xgl) {
        margin-bottom: rem(36);
    }
    select {
        width: 100%;
        border: 1px solid $gray-020;
        border-radius: rem(25);
        height: rem(50);
        text-align: center;
        &:focus {
            outline: none;
        }
        option {
            text-align: center;
        }
    }
}

.container--sliderExpoProduct {
    
    @include media-breakpoint-up(xgl) {
        padding-left: 30%;
    }
}
.sliderExpoProduct {
    width:100%;
    // height:700px;
    position: absolute !important;
    clear:both;
    overflow:hidden;
    // padding-bottom: rem(20);
    img {
        visibility:hidden; /* hide images until carousel can handle them */
        cursor:pointer; /* otherwise it's not as obvious items can be clicked */
        width: 40%;
        height: auto;
    }
}

.sliderExpoProduct--container {
    z-index: 50;
}

.sliderExpoProduct-dots {
    list-style: none;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    @include media-breakpoint-up(xgl) {
        display: none;
    }
    @include media-breakpoint-up(sm) {
        bottom: rem(20);
    }
    li {
        top: 0;
        left: 0;
        width: rem(17);
        height: rem(17);
        background-color: $gray-300;
        border-radius: 50%;
        display: inline-block;
        margin: 0 rem(4);
        cursor: pointer;
        transition: 0.2s all;
        &.active {
            background-color: $orange;
        }
    }    
}