.container-territoire {
    margin-bottom: rem(127);
    @include media-breakpoint-down(sm) {
        margin-bottom: rem(65);
    }
    .h1 {
        color: $orange;
        text-align: center;
        margin-bottom: rem(51);
        @include media-breakpoint-down(sm) {
            margin-bottom: rem(18);
        }
    }
    .pos-a-container {
        
        @include media-breakpoint-up(sm) {
            bottom: rem(-40);
        }
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
            position: static;
        }
        
    }
}
.territoire {
    &__body {
        position: relative;
        img {
            width: 100%;
        }
    }
    &__card {
        @include media-breakpoint-up(sm) {
            margin-left: auto;
        }
        @include media-breakpoint-down(sm) {
            max-width: 100%;
        }
        
        h3 {
            color: $orange;
            margin-bottom: rem(23);
        }
    }
}


.bandeau__body {
    position: relative;
}

.scroll-to-next {
    height: 62px;
    width: 43px;
    border: 4px solid $black;
    display: block;
    border-radius: 20px;
    position: absolute;
    bottom: 0;
    right: 24px;
    &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 11px;
        background-color: $black;
        top: 17px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 2px;
    }
}