/// Grid system
//
// Generate semantic grid columns with these mixins.

@include media-breakpoint-down(xs) {
    $grid-gutter-width: 32px;
    .container {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }
    .row {
        margin-left: $grid-gutter-width / -2;
        margin-right: $grid-gutter-width / -2;
    }
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-xsm-1, .col-xsm-2, .col-xsm-3, .col-xsm-4, .col-xsm-5, .col-xsm-6, .col-xsm-7, .col-xsm-8, .col-xsm-9, .col-xsm-10, .col-xsm-11, .col-xsm-12, .col-xsm, .col-xsm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-mdx-1, .col-mdx-2, .col-mdx-3, .col-mdx-4, .col-mdx-5, .col-mdx-6, .col-mdx-7, .col-mdx-8, .col-mdx-9, .col-mdx-10, .col-mdx-11, .col-mdx-12, .col-mdx, .col-mdx-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }
}


@media (min-width: 1199.98px) and (max-width: 1920px) {
    .container {
        max-width: calc(1600px + 10%);
        padding-left: 5%;
        padding-right: 5%;
    }
}