@mixin linear-gradient($from, $to) {
  background: $to;
	background: linear-gradient(to bottom, $from 0%,$to 100%);
	background: -moz-linear-gradient(top, $from 0%, $to 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$from), color-stop(100%,$to));
	background: -webkit-linear-gradient(top,  $from 0%,$to 100%); /* Chrome10+,Safari5.1+ */
	background: -webkit-linear-gradient(top, $from 0%,$to 100%);
	background: -o-linear-gradient(top, $from 0%,$to 100%);
	background: -ms-linear-gradient(top, $from 0%, $to 100%); /* IE10+ */
	filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#{$from}, endColorstr=#{$to});
}

@mixin radial-gradient($from, $to) {
  background: $to;
	background: -moz-radial-gradient(center, ellipse cover, $from 0%, to 100%);
	background: -webkit-radial-gradient(center, ellipse cover, $from 0%,$to 100%);
	background: radial-gradient(ellipse at center, $from 0%,$to 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$from}', endColorstr='#{$to}',GradientType=1 );
}


