.link {
    font-size: rem(17);
    &:before {
        content: '';
        display: inline-block;
        background: url(#{$image}bee-btn-icon.svg) no-repeat;
        width: rem(26);
        height: rem(23);
        background-size: cover;
        margin-right: rem(8);
        vertical-align: middle;
    }
    &:hover {
        &::before {
            background-image: url(#{$image}bee-btn-icon-orange.svg);
        }
    }
}

.b-produit {
    display: flex;
    min-height: rem(250);
    padding: rem(25) rem(15);
    position: relative;
    justify-content: center;
    @include media-breakpoint-up(xgl) {
        height: 33%;
    }
    &:not(:last-child) {
        margin-bottom: rem(5);
    }
    @include linear-gradient($white, $gray-100);
    @include media-breakpoint-down(sm) {
        padding-left: 24px;
        padding-right: 24px;
        margin-bottom: rem(10);
        min-height: rem(180);
    }
    @include media-breakpoint-down(xs) {
        padding-left: 3%;
        padding-right: 3%;
    }
    &__thumb {
        width: 100%;
        max-width: rem(140);
        margin-right: rem(10);
        min-height: rem(210);
        @include media-breakpoint-down(sm) {
            max-width: rem(110);
            display: flex;
            align-items: center;
        }
    }
    &__body {
        position: relative;
        padding-bottom: rem(23);
        max-width: rem(250);
        align-self: center;
        min-height: rem(110);
        @include media-breakpoint-up(xgl) {
            width: 100%;
            max-width: 100%;
        }
        .link-decouvrir {
            float: right;
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }
    .h3 {
        color: $primary;
        line-height: 1.125;
        margin-top: rem(10);
        margin-bottom: rem(10);
        @include media-breakpoint-down(sm) {
            font-size: rem(30);
        }
        // @include media-breakpoint-between(sm, xgl) {
        //     font-size: vw(40);
        // }
    }
    .h5 {
        color: $primary;
        // @include media-breakpoint-between(sm, xgl) {
        //     font-size: vw(30);
        // }
        @include media-breakpoint-down(sm) {
            font-size: rem(21);
        }
    }
}