// ========================================= //
// fonts
// ========================================= //

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 3, 2018 */


$openSans: 'Open Sans', sans-serif !default;

$libreBaskerville: 'Libre Baskerville', serif !default;

$fontBody: $openSans;

$fontTitle: $libreBaskerville;
