.form-group {
	position: relative;
	transition: 0.3s all;
	label.error {
		position: absolute;
		bottom: -20px;
		left: 15px;
	}
	&.error {
		.form-control {
			border: 1px solid transparentize($pink, 0.75);
		}
	}
	.form-control.error {
		border: 1px solid transparentize($pink, 0.75);
	}
	&.valid {
		background: url(#{$image}checker-valid.svg) no-repeat;
		background-position: center right;
		padding-right: rem(30);
	}
}

label {
	&.error {
		color: $pink;
		text-align: left;
		font-size: rem(10);
	}
}


.custom-control {
	position: relative;
	label.error {
		position: absolute;
		bottom: -20px;
		left: 0;
	}
}