.container-suivre-actualites {
    padding-left: rem(42);
    padding-right: rem(42);
    max-width: rem(1448);
    margin-bottom: rem(30);
    @include media-breakpoint-down(lg) {
        padding-left: 24px;
        padding-right: 24px;
    }
    @include media-breakpoint-down(sm) {
        padding-left: 35px;
        padding-right: 35px;
    }
    @include media-breakpoint-down(xs) {
        padding-left: 16px;
        padding-right: 16px;
    }
    .h1 {
        color: $orange;
        text-align: center;
        margin-bottom: rem(52);
        @include media-breakpoint-down(sm) {
            margin-bottom: rem(10);
        }
    }

    .row {
        margin-left: -42px;
        margin-right: -42px;
        @include media-breakpoint-down(lg) {
            margin-left: -24px;
            margin-right: -24px;
        }
        @include media-breakpoint-down(sm) {
            margin-left: -35px;
            margin-right: -35px;
        }
        @include media-breakpoint-down(xs) {
            margin-left: -16px;
            margin-right: -16px;
        }

        [class*="col-"] {
            padding-left: 42px;
            padding-right: 42px;
            @include media-breakpoint-down(lg) {
                padding-left: 24px;
                padding-right: 24px;
            }
            @include media-breakpoint-down(sm) {
                padding-left: 35px;
                padding-right: 35px;
            }
            @include media-breakpoint-down(xs) {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }
}

.actualites-social-network {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: rem(70);
    @include media-breakpoint-up(md) {
        margin-left: -10px;
        margin-right: -10px;
    }
    
    @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin-bottom: rem(15);
    }
    li {
        margin: rem(10) rem(30);
        @include media-breakpoint-down(lg) {
            margin: rem(10);
        }
        @include media-breakpoint-down(sm) {
            margin: rem(8) 0;
            width: 100%;
        }
    }
    a {
        border: 1px solid $gray-300;
        color: $orange;
        font-size: 1.25rem;
        font-weight: 700;
        height: rem(70);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: rem(10) rem(30);
        vertical-align: middle;
        transition: 0.3s all;
        position: relative;
        @include media-breakpoint-down(sm) {
            padding: rem(10) rem(5) rem(10) rem(50);
            font-size: rem(15);
            height: rem(50);
        }
        
        &:before {
            @include media-breakpoint-up(sm) {
                margin-right: rem(30);
            }
            @include media-breakpoint-down(sm) {
                position: absolute;
                left: rem(15);
            }
        }
        &:hover {
            color: $black;
            
        }
    }
}

.icon-facebook {
    &:before {
        content: '';
        width: 18px;
        height: 35px;
        display: inline-block;
        vertical-align: middle;
        background: url(#{$image}Facebook.svg) no-repeat;
        background-size: 18px 35px;
        @include media-breakpoint-down(sm) {
            width: 28px;
            height: 28px;
            background-size: 15px 28px;
        }
    }
    &:hover:before {
        background-image: url(#{$image}Facebook-black.svg);
    }
}


.icon-instagram {
    &:before {
        content: '';
        width: 35px;
        height: 35px;
        display: inline-block;
        vertical-align: middle;
        background: url(#{$image}Instagram.svg) no-repeat;
        background-size: 35px 35px;
        @include media-breakpoint-down(sm) {
            width: 28px;
            height: 28px;
            background-size: 28px 28px;
        }
    }
    &:hover:before {
        background-image: url(#{$image}Instagram-black.svg);
    }
}

.icon-google {
    &:before {
        content: '';
        width: 35px;
        height: 35px;
        display: inline-block;
        vertical-align: middle;
        background: url(#{$image}google.svg) no-repeat;
        background-size: 35px 35px;
        @include media-breakpoint-down(sm) {
            width: 28px;
            height: 28px;
            background-size: 28px 26px;
        }
    }
    &:hover:before {
        background-image: url(#{$image}google-black.svg);
    }
}


.b-actualite {
    margin-bottom: rem(56);
    &__thumb {
        margin-bottom: rem(24);
        
        img {
            width: 100%;
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: rem(21);
            margin-left: -35px;
            margin-right: -35px;
            
        }
        @include media-breakpoint-down(xs) {
            margin-left: -16px;
            margin-right: -16px;
        }
    }
    h3 {
        line-height: 1.84;
        
        margin-bottom: rem(10);
        color: $orange;
        @include media-breakpoint-down(sm) {
            line-height: 1.16;
        }
    }
    .p {
        margin-bottom: rem(20);
    }
    .btn {
        margin-top: rem(20);
        @include media-breakpoint-up(sm) {
            float: right;
        }
        @include media-breakpoint-down(sm) {
            max-width: 220px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        
    }
}