/* Slider */

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }

}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
    &, &:focus {
        outline: none;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}





/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 30%;

    display: block;

    width: rem(38);
    height: rem(26);
    padding: 0;

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    @include media-breakpoint-down(sm) {
        width: rem(26);
        height: rem(18);
    }
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    content: '';
    background-repeat: no-repeat;
    width: rem(38);
    height: rem(26);
    background-size: cover;
    display: block;
    @include media-breakpoint-down(sm) {
        width: rem(26);
        height: rem(18);
    }
}

.slick-prev
{
    left: 0;
}
[dir='rtl'] .slick-prev
{
    right: 0;
    left: auto;
}
.slick-prev:before
{
    background-image: url(#{$image}right-arrow.svg);
    @include media-breakpoint-down(sm) {
        background-image: url(#{$image}right-arrow-black.svg);
    }

}
[dir='rtl'] .slick-prev:before
{
    content: '';

}

.slick-next
{
    right: 0;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: 0;
}
.slick-next:before
{
    background-image: url(#{$image}left-arrow.svg);
    @include media-breakpoint-down(sm) {
        background-image: url(#{$image}left-arrow-black.svg);
    }
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: rem(30);
}

.slick-dots
{
    position: absolute;
    bottom: rem(25);

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: rem(22);
    height: rem(22);
    margin: 0 rem(6);
    padding: 0;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
        width: rem(17);
        height: rem(17);
        margin: 0 rem(5);
    }
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: rem(22);
    height: rem(22);
    padding: rem(6);

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;

    @include media-breakpoint-down(sm) {
        width: rem(17);
        height: rem(17);
    }
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{


    position: absolute;
    top: 0;
    left: 0;

    width: rem(22);
    height: rem(22);

    content: '';
    background-color: $white;
    border-radius: 50%;
    @include media-breakpoint-down(sm) {
        width: rem(17);
        height: rem(17);
    }
}
.slick-dots li.slick-active button:before
{
    background-color: $orange;
}