#form-newsletter {
	@include media-breakpoint-down(xgl) {
		margin-bottom: rem(20);
	}
	@include media-breakpoint-up(xgl) {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
    .form-group {
		position: relative;
		margin-bottom: 0;
		max-width: rem(430);
		width: 100%;
		@include media-breakpoint-down(xgl) {
			margin-left: auto;
			margin-right: auto;
		}
		
		&.valid {
			[class*="btn"] {
				right: rem(30);
			}
		}
	}
	label:not(.error) {
		font-size: rem(16);
		margin-bottom: rem(8);
		display: block;
		text-align: center;
		
		@include media-breakpoint-up(sm) {
			margin-right: 0;
			font-size: rem(20);
		}
		@include media-breakpoint-up(xgl) {
			margin-right: rem(40);
		}
	}
    .form-control,
    [class*="btn"] {
		height: rem(56);
		@include media-breakpoint-down(sm) {
			height: rem(44);
		}
    }
    .form-control {
        padding-right: rem(130);
		margin-bottom: 0;
		font-size: rem(19);
		max-width: rem(400);
		width: 100%;
		@include media-breakpoint-down(md) {
			font-size: rem(14);
			padding-left: rem(20);
			padding-right: rem(10);
		}
		@include media-breakpoint-down(xs) {
			padding-left: rem(10);
		}
    }
    [class*="btn"]  {
        position: absolute;
        right: 0;
        top: 0;
		bottom: 0;
		color: $white;
		font-size: rem(22);
		cursor: pointer;
		border: 1px solid transparent;
		@include transition($btn-transition);
		padding: rem(13) rem(16);
		background-color: $orange;
		transition: 0.3s all;
		@include media-breakpoint-down(md) {
			font-size: rem(17);
			padding: rem(10) rem(13);
		}
		&:hover {
			background-color: $black;
			color: $white;
		}
    }
}