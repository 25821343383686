.c-nav__close {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.83);
    display: none;
    z-index: 1035;
    cursor: pointer;
    &.is-active {
        display: block;
    }
}

.navbarHeader--logo {
    @include media-breakpoint-down(sm) {
        position: relative;
        padding-left: 30px;
        text-align: center;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        .navbar-toggler {
            position: absolute;
            left: 0;
        }
    }
}

#navbarHeader {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: $white;
    z-index: 1040;
    box-shadow: 0 0 10px rgba(#000000, 0.16);
    max-width: 430px;
    width: 100%;
    transform: translateX(-110%);
    transition: 0.2s all;
    overflow-y: scroll;
    padding: rem(55) rem(50);
    @include media-breakpoint-down(sm) {
        padding: rem(10) rem(35) rem(50);
        max-width: 360px;
    }
    @include media-breakpoint-down(xs) {
        padding-left: 3%;
        padding-right: 3%;
    }
    .navbar-toggler-icon {
        background-color: transparent;
        &:before {
            transform: rotate(45deg);
            top: 0;
        }
        &:after {
            transform: rotate(-45deg);
            top: 0;
        }
    }
    &.is-opened {
        transform: translateX(0);
    }
    
    .nav-item {
        margin-top: rem(16);
        margin-bottom: rem(16);
        
        
        &:last-child {
            background-color: $orange;
            color: $white;
            margin-left: rem(-50);
            margin-right: rem(-50);
            @include media-breakpoint-down(sm) {
                margin-left: rem(-35);
                margin-right: rem(-35);
            }

            .nav-link {
                text-align: center;
                color: $white;
                padding-left: rem(50);
                padding-right: rem(50);
                @include media-breakpoint-down(sm) {
                    padding-left: rem(35);
                    padding-right: rem(35);
                }
            }
            
        }
    }
    .nav-link {
        font-family: $libreBaskerville;
        font-size: rem(25);
        line-height: 1.24;
        position: relative;
        @include media-breakpoint-down(sm) {
            font-size: rem(24);
            line-height: 1.2;
            padding-top: rem(10);
            padding-bottom: rem(10);
        }
        &.has-child {
            &:before {
                content: '';
                position: absolute;
                display: block;
                height: 2px;
                width: 14px;
                background-color: $black;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                @include media-breakpoint-down(sm) {
                    width: 12px;
                }
            }
            &:after {
                content: '';
                position: absolute;
                display: block;
                height: 14px;
                width: 2px;
                background-color: $black;
                right: 6px;
                top: 50%;
                transform: translateY(-50%);
                opacity: 1;
                transition: 0.2s opacity;
                @include media-breakpoint-down(sm) {
                    height: 12px;
                    right: 5px;
                }
            }
            &.is-active {
                color: $orange;
                &:before {
                    background-color: $orange;
                }
                &:after {
                    opacity: 0;
                }
            }
        }
        &.active {
            color: $orange;
        }
        &.is-active {
            color: $orange;
        }
    }
    .navbar-toggler {
        margin-bottom: rem(40); 
    }
}

.nav-lavel2 {
    display: none;
    li {
        margin-top: rem(10);
        margin-bottom: rem(10);
    }
    a {
        display: block;
        position: relative;
        padding-left: rem(30);
        padding-top: rem(10);
        padding-bottom: rem(10);
        font-size: rem(18);
        font-family: $libreBaskerville;
        @include media-breakpoint-down(lg) {
            font-size: rem(16);
            padding-left: rem(20);
        }
        &:before {
            content: '';
            display: block;
            position: absolute;
            width: rem(15);
            height: rem(15);
            background-color: $gray-300;
            border-radius: 50%;
            left: 0;
            transition: 0.3s background-color;
            top: 50%;
            transform: translateY(-50%);
            @include media-breakpoint-down(lg) {
                width: rem(10);
                height: rem(10);
            }
        }
        &:hover,
        &.active {
            color: $orange;
            &:before {
                background-color: $orange;
            }
        }
    }
}

.navbar-toggler {
	@include media-breakpoint-down(sm) {
		width: 24px;
	}
}

.navbar-toggler-icon {
	transition: 0.3s all;
	@include media-breakpoint-down(sm) {
		width: 24px;
		&:before {
			top: -10px;
		}
		&:after {
			top: 10px;
		}
	}
}