footer {
    padding-top: rem(20);
    padding-bottom: rem(20);
    &.container {
        max-width: 1508px;
        @media (min-width: 1199.98px) and (max-width: 1920px) {
            max-width: calc(1460px + 10%);
            padding-left: 5%;
            padding-right: 5%;
        }
    }
    @include media-breakpoint-down(sm) {
        padding-top: rem(25);
        box-shadow: 0 3px 10px rgba(0,0,0,0.16);
    }
}

.footer {
    &__top {
        &--logo {
            text-align: center;
            img {
                @include media-breakpoint-down(sm) {
                    max-width: 85%;
                }
            }
            .p {
                font-size: rem(20);
                margin-top: rem(20);
                margin-bottom: rem(20);
                color: $black;
                @include media-breakpoint-down(xgl) {
                    margin-bottom: 0;
                }
                @include media-breakpoint-down(sm) {
                    font-size: rem(16);
                    max-width: rem(170);
                    margin: rem(15) auto 0;
                    span {
                        display: none;
                    }
                }
            }
        }
    }
    &__middle {
        border-top: 1px solid transparentize($gray-700, 0.6);
        border-bottom: 1px solid transparentize($gray-700, 0.6);
        .h3 {
            margin-bottom: 0;
            padding-top: rem(20);
            padding-bottom: rem(20);
            display: flex;
            align-items: center;
            justify-content: center;
            @include media-breakpoint-down(sm) {
                padding-top: rem(14);
                padding-bottom: rem(14);
            }
            a {
                text-align: center;
                color: $orange;
                font-size: rem(30);
                @include media-breakpoint-down(sm) {
                    font-size: rem(20);
                    line-height: 1.2;
                }
                @include media-breakpoint-up(xgl) {
                    &:after {
                        content: '';
                        background-image: url(#{$image}icon-bee.svg);
                        width: rem(138);
                        height: rem(39);
                        display: inline-block;
                        background-size: cover;
                        margin-left: rem(7);
                        transition: 0.3s background-image;
                    }
                }
                
                &:hover {
                    color: $black;
                    &:after {
                        background-image: url(#{$image}icon-bee-black.svg);
                    }
                }
            }
            
        }
    }
    &__bottom {
        padding-top: rem(30);
        padding-bottom: rem(30);
        @include media-breakpoint-down(xgl) {
            padding-top: rem(20);
            padding-bottom: rem(20);
        }
    }
}

.nav-copyright {
    @include media-breakpoint-up(xgl) {
        padding-top: rem(20);
        padding-bottom: rem(20);
    }
    ul {
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
    }
    @include media-breakpoint-up(sm) {
        li {
            &:not(:last-child) {
                &:after {
                    content: '-';
                    margin-left: rem(10);
                    margin-right: rem(10);
                    
                }
            }
        }
    }
    
    li, span, a {
        color: $gray-700;
        @include media-breakpoint-down(sm) {
            font-size: rem(12);
            line-height: 1.41;
        }
    }
    a:hover {
        color: $orange;
    }
}

.nav-footer {
    
    @include media-breakpoint-up(xgl) {
        margin-top: rem(30);
    }
    ul {
        margin-bottom: 0;
        padding: rem(15) 0;
        list-style: none;
        display: flex;
        flex-wrap: nowrap;
        margin-left: rem(-10);
        margin-right: rem(-10);
        align-items: center;
        justify-content: space-between;
        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }
    li {
        text-align: center;
    }
    a {
        font-size: rem(25);
        font-family: $fontTitle;
        @include media-breakpoint-down(md) {
            flex-direction: column;
            font-size: rem(20);
            margin-top: rem(4);
            margin-bottom: rem(4);
        }
    }
}


.nav--social__links {
    justify-content: flex-end;
    @include media-breakpoint-down(xgl) {
        justify-content: center;
    }
    ul {
        display: flex;
        align-items: center;
        @include media-breakpoint-up(xgl) {
            margin-right: rem(-22);
        }
        a {
            width: rem(30);
            height: rem(30);
            margin: rem(11) rem(22);
            display: flex;
            align-items: center;
            justify-content: center;
            transition: none;
        }
        [class*="link"] {
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}


.link-facebook {
	background-image: url(#{$image}Facebook.svg);
    background-size: rem(16) rem(30);
    &:hover {
        background-image: url(#{$image}Facebook-black.svg);
    }
}
.link-instagram {
	background-image: url(#{$image}Instagram.svg);
    background-size: rem(30) rem(30);
    &:hover {
        background-image: url(#{$image}Instagram-black.svg);
    }
}
.link-google {
	background-image: url(#{$image}google.svg);
    background-size: rem(26) rem(26);
    &:hover {
        background-image: url(#{$image}google-black.svg);
    }
}