header {
	height: 150px;
	display: flex;
	background-color: $white;
	transition: 0.3s all;
	&.is-sticky {
		@include media-breakpoint-up(md) {
			height: 90px;
			.navbar-toggler {
				width: 30px;
			}
			.navbar-toggler-icon {
				width: 30px;
				&:before {
					top: -10px;
				}
				&:after {
					top: 10px;
				}
			}
			.header-logo {
				transform: scale(0.6);
			}
			.nav-header a {
				font-size: rem(16);
			}
			.btn-search {
				width: 22px;
				height: 22px;
			}
		}
		
	}
	@include media-breakpoint-down(sm) {
		height: 60px;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 1020;
	}
    align-items: center;
	.row {
		align-items: center;
		@include media-breakpoint-down(sm) {
			margin-left: 0;
			margin-right: 0;
			position: relative;
			justify-content: center;
			padding-left: 30px;
			.col-navbar-toggle {
				position: absolute;
				left: 0;
			}
		}
	}
	
	[class*="col-"] {
		@extend .col-sm-4;
		@include media-breakpoint-down(sm) {
			padding-left: 0;
			padding-right: 0;
		}
	}
	
	
}
.header-logo {
	display: block;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	padding-top: rem(10);
	padding-bottom: rem(10);
	text-align: center;
	max-width: 292px;
	@include media-breakpoint-up(sm) {
		transform: scale(1);
		transition: 0.3s all;
	}
}

.nav-header {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-end;
	margin-right: -24px;
	@include media-breakpoint-down(md) {
		margin-right: -5px;
	}
	li {
		padding: rem(10) rem(30);
		@include media-breakpoint-down(md) {
			padding: rem(10) rem(5);
		}
	}
	a {
		display: inline-block;
		font-family: $libreBaskerville;
		font-size: rem(20);
		transition: 0.3s all;
	}
}

.btn-search {
	width: rem(30);
	height: rem(30);
	background: url(#{$image}Search.svg) no-repeat;
	background-size: cover;
	transition: 0.3s all;
}