.select2-container {
  box-sizing: border-box;

  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
  &.select2-container--open {
    .select2-selection--single .select2-selection__rendered:after {
      transform: rotate(180deg);
    }
  }
  &:focus {
    outline: 0 none;
  }

  // single
  .select2-selection--single {
    box-sizing: border-box;

    cursor: pointer;
    display: block;

    height: rem(50);

    user-select: none;
    -webkit-user-select: none;
    position: relative;
    &:focus {
      outline: 0 none;
    }
    .select2-selection__rendered {
      position: relative;
      z-index: 1025;
      display: block;
      padding: rem(11) rem(16) rem(12) rem(14);
      border: 1px solid $gray-020;
      font-size: rem(14);
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      outline: 0 none;
      border-radius: rem(25);
      text-align: center;
      font-size: rem(20);
      &:after {
        content: '';
        width: rem(14);
        height: rem(10);
        margin-left: rem(20);
        background: url(#{$image}arrow-down.svg) no-repeat;
        background-size: cover;
        display: inline-block;
        vertical-align: middle;
        transition: 0.3s all;
      }
    }

    .select2-selection__clear {
      position: relative;
    }
  }

  &[dir="rtl"] {
    .select2-selection--single {
      .select2-selection__rendered {
        padding-right: rem(8);
        padding-left: rem(20);
      }
    }
  }
  // multiple
  .select2-selection--multiple {
    box-sizing: border-box;

    cursor: pointer;
    display: block;

    min-height: rem(32);

    user-select: none;
    -webkit-user-select: none;

    .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: rem(8);
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .select2-search--inline {
    float: left;

    .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: rem(5);
      padding: 0;

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
    }
  }
}

// dropdown

.select2-dropdown {
  
  background-color: #fff;
  box-sizing: border-box;

  display: block;

  position: absolute;
  left: -100000px;

  width: 100%;

  z-index: 1020;
  border: 1px solid $gray-300;
  border-radius: rem(25);
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  
}

.select2-results__option {
  color: $black;
  padding: rem(10) rem(20);
  transition: 0.3s all;
  text-align: center;
  font-size: rem(20);
  user-select: none;
  -webkit-user-select: none;
  &:first-child {
    display: none;
  }
  &[aria-selected] {
    cursor: pointer;
  }
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: rem(16);
  padding-bottom: rem(40);
  margin-top: 25px;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: rem(40);
  padding-bottom: rem(16);
  position: relative;
  margin-top: -25px;
  &:before {
    position: absolute;
    content: '';
    width: 15px;
    height: 12px;
    background-size: cover;
    display: block;
    top: 15px;
    right: 15px;
    transform: rotate(180deg);
  }
}

.select2-search--dropdown {
  display: block;
  padding: 4px;

  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
    }
  }

  &.select2-search--hide {
    display: none;
  }
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;

  // styles required for IE to work

  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}