.s-reference {
	box-shadow: 0 3px 20px rgba(0,0,0,0.16);
	padding-top: rem(56);
	padding-bottom: rem(54);
	background-color: $white;
	margin-bottom: rem(60);
	@include media-breakpoint-down(sm) {
		box-shadow: 0 0 6px rgba(0,0,0,0.16);
		padding-top: rem(30);
		padding-bottom: rem(20);
		margin-bottom: rem(60);
		.nav-slick-arrow {
			text-align: center;
			margin-top: rem(15);
		}
	}
}

.reference__intro {
	max-width: rem(945);
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	margin-bottom: rem(60);
	@include media-breakpoint-down(sm) {
		margin-bottom: rem(13);
    }
	.h2 {
		color: $black;
		line-height: 1.44;
		margin-bottom: rem(14);
	}
}

.slider-reference {
	padding-left: rem(70);
	padding-right: rem(70);
	@include media-breakpoint-down(sm) {
		padding-left: rem(40);
		padding-right: rem(40);
	}
	a {
		outline: none;
	}
	&__item {
		display: flex !important;
		justify-content: center;
		align-items: center;
		padding: rem(10);
		outline: none;
		@include media-breakpoint-down(sm) {
			padding: rem(10);
		}
	}
	@include media-breakpoint-up(xs) {
		.slick-next {
			right: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			&:before {
				background-image: url(#{$image}left-arrow-black.svg);
			}
			
		}
		.slick-prev {
			left: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			&:before {
				background-image: url(#{$image}right-arrow-black.svg);
			}
		}
	}
	
	
}