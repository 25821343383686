@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

$min_width: 767px;
$max_width: 1920px;
$min_font: 13px;
$max_font: 16px;


$mod_1: 1.2; // mobile
$mod_2: 1.5; // desktop


  
@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
$u1: unit($min-vw);
$u2: unit($max-vw);
$u3: unit($min-font-size);
$u4: unit($max-font-size);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            font-size: $min-font-size;
            @media screen and (min-width: $min-vw) {
                font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
            }
            @media screen and (min-width: $max-vw) {
                font-size: $max-font-size;
            }
        }
    }
}

html {
	box-sizing: border-box;
	height: 100%;
	font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    @include fluid-type($min_width, $max_width, $min_font, $max_font);
}

body {
    padding-top: 150px;
    @include media-breakpoint-down(sm) {
        padding-top: 60px;
	}
    &.is-clipped {
        width: 100%;
        height: 100px;
        overflow: hidden;
    }
}

.pos-a-container {
    @include make-container();
    max-width: 1688px;
    position: absolute;
    left: 0;
    right: 0;
    @include media-breakpoint-up(xgl) {
        max-width: calc(1600px + 10%);
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
    color: $orange;
    &.black {
        color: $black !important;
    }
}

h1, .h1 {
    font-size: rem(75);
    @include media-breakpoint-down(sm) {
        font-size: rem(35);
    }
}
h2, .h2 {
    font-size: rem(50);
    @include media-breakpoint-down(sm) {
        font-size: rem(29);
    }
}
h3, .h3 {
    font-size: rem(40);
    @include media-breakpoint-down(sm) {
        font-size: rem(25);
    }
}

h4, .h4 {
    font-size: rem(35);
    @include media-breakpoint-down(sm) {
        font-size: rem(20);
    }
}

h5, .h5 {
    font-size: rem(25);
    @include media-breakpoint-down(sm) {
        font-size: rem(18);
    }
}

.mb-35 {
    margin-bottom: rem(35) !important;
}

@include media-breakpoint-up(lg) {
    .mb-up-lg-80 {
        margin-bottom: rem(80) !important;
    }    
}

.max-1640 {
    max-width: 1640px !important;
}

.max-535 {
    max-width: 535px;
}
.max-560 {
    max-width: 560px;
}
.max-630 {
    max-width: 630px;
}


.max-500 {
    max-width: 500px;
}


.max-740 {
    max-width: 740px;
}

.max-720 {
    max-width: 720px !important;
}


.pb-80 {
    padding-bottom: rem(80);
}


.text-lineHeight {
    position: relative;
    span {
        cursor: pointer;
        color: $gray-700;
    }
	&.is-hidden {
        overflow: hidden;
        padding-right: rem(22);
		span {
			position: absolute;
			right: 0;
            bottom: 0;
		}
	}
	&:not(.is-hidden) {
		height: auto !important;
    }
}


.cover,
.bg-contain {
    background-repeat: no-repeat;
    background-position: center;
}
.cover {
    background-size: cover;
    
}
.bg-contain {
    background-size: contain;
}

.opacity-zero {
    opacity: 0 !important;
}