.intro-text {
    padding-top: rem(48);
    padding-bottom: rem(25);
    &__container {
        max-width: rem(940);
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
        }
        
        
    }
    &__thumb {
        flex: 0 0 auto;
        max-width: rem(140);
        @include media-breakpoint-up(sm) {
            margin-right: rem(35);
        }
        @include media-breakpoint-down(sm) {
            margin-bottom: rem(12);
            max-width: rem(85);
            margin-left: auto;
            margin-right: auto;
        }
    }
    h2, .h2 {
        color: $black;
        text-align: center;
        margin-bottom: rem(14);
        @include media-breakpoint-down(sm) {
            margin-bottom: 1rem !important;
        }
    }
}

.intro-produit {
    @include media-breakpoint-up(sm) {
        padding-top: rem(90);
        padding-bottom: rem(45);
    }
}