.container-bandeau-ruche {
    position: relative;
    text-align: center;
    color: $white;
    padding-top:rem(60);
    padding-bottom: rem(60);
    font-size: 0.8333333333333333vw;
    @include media-breakpoint-up(xl) {
        font-size: 16px;
    }
    
    .container {
        position: relative;
        z-index: 1;
        @include media-breakpoint-down(sm) {
            padding-left: 35px;
            padding-right: 35px;
        }
    }
    @include media-breakpoint-down(sm) {
        padding-top: rem(30);
    }
    
    h2, .h2 {
        color: inherit;
        margin-bottom: rem(30);
        @include media-breakpoint-down(sm) {
            font-size: rem(25);
        }
    }
    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: em(1300);
        background: #ecb041;
        background: -moz-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 200%);
        background: -webkit-radial-gradient(center, ellipse cover, #ecb041 0%,#cf9338 200%);
        background: radial-gradient(ellipse at center, #ecb041 0%,#cf9338 200%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ecb041', endColorstr='#cf9338',GradientType=1 );
        @include media-breakpoint-up(sm) {
            height: auto;
            top: 0;
            bottom: em(207);
            background: #ecb041;
            background: -moz-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 100%);
            background: -webkit-radial-gradient(center, ellipse cover, #ecb041 0%,#cf9338 100%);
            background: radial-gradient(ellipse at center, #ecb041 0%,#cf9338 100%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cf9338', endColorstr='#ecb041',GradientType=1 );
        }
    }

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        top: em(1200);
        left: 0;
        right: 0;
        background: #ecb041;
        background: -moz-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 360%);
        background: -webkit-radial-gradient(center, ellipse cover, #ecb041 0%,#cf9338 360%);
        background: radial-gradient(ellipse at center, #ecb041 0%,#cf9338 360%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cf9338', endColorstr='#ecb041',GradientType=1 );
        @include media-breakpoint-up(sm) {
            height: em(300);
            top: auto;
            background: #ecb041;
            bottom: 0;
            background: -moz-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 260%);
            background: -webkit-radial-gradient(center, ellipse cover, #ecb041 0%,#cf9338 260%);
            background: radial-gradient(ellipse at center, #ecb041 0%,#cf9338 260%);
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cf9338', endColorstr='#ecb041',GradientType=1 );
        }
    }
    
    

    img {
        position: relative;
    }
}

.libelle {
    position: relative;
    padding-left:rem(38);
    @include media-breakpoint-down(sm) {
        margin-bottom:rem(20);
    }
    &-title {
        line-height: 1.36;
        font-size:rem(25);
        @include media-breakpoint-up(sm) {
            font-size:em(41);
            line-height: 1.15;
        }
    }
    &-description {
        margin-top:em(10);
        font-size: rem(16);
        @include media-breakpoint-up(sm) {
            line-height: 1.375;
            font-size: em(16);
        }
    }
    &-title,
    &-description {
        color: $white;
        font-family: $fontBody;
        @include media-breakpoint-up(sm) {
            margin-bottom: 0;
        }
        
        @include media-breakpoint-down(sm) {
            text-align: left;
        }
    }
    &-number {
        display: block;
        width: rem(22);
        height: rem(22);
        line-height: rem(22);
        border-radius: 50%;
        font-size: rem(18);
        background-color: $white;
        color: $black;
        text-align: center;
        margin-right: 1rem;
        font-family: $openSans;
        font-weight: 700;
        position: absolute;
        top: rem(5);
        left: 0;
    }
}


// Ruche Desktop


.ruche__container {
    &--imageText--item {
        display: inline-block;
        position: relative;
        transform: scale(1);
        transition: 0.3s transform;
        cursor: pointer;
        @include media-breakpoint-up(sm) {
            &:hover {
                transform: scale(1.05);
                .ruche__container--item--text {
                    opacity: 1;
                    visibility: visible;                
                }
            }
            &.item7:hover {
                transform: scale(1);
            }
        }
        
    }
    &--item--text {
        position: absolute;
        right: 0;
        transform: translateX(100%);
        text-align: left;
        @include media-breakpoint-up(sm) {
            opacity: 0;
            visibility: hidden;
            transition: 0.3s all;
        }
        
        &::before {
            content: '';
            display: block;
            position: absolute;
            height: 1px;
            background-color: $white;
        }
        @include media-breakpoint-down(sm) {
            .libelle-description,
            .libelle-title {
                display: none;
            }
        }
        .libelle-number {
            position: static;
            margin-right: 0;            
        }
        @include media-breakpoint-up(sm) {
            .libelle-number {
                display: none;
            }
        }
    }
}
.ruche {
    
    &__container--position {
        position: relative;
    }
    img:not(.img10) {
        -webkit-filter: drop-shadow(10px 3px 30px rgba(0,0,0,0.35));
        filter: drop-shadow(10px 3px 30px rgba(0,0,0,0.35));
        @include media-breakpoint-down(sm) {
            -webkit-filter: drop-shadow(5px 3px 8px rgba(0,0,0,0.35));
            filter: drop-shadow(5px 3px 8px rgba(0,0,0,0.35));
        }
    }
    .item1 {
        width: em(579);
        max-width: 579px;
        .ruche__container--item--text {
            top:em(65);
            transform: translateX(100%);
            padding-left:em(90);
            text-align: left;
            @include media-breakpoint-down(sm) {
                top:em(50);
                padding-left:em(70);
            }
            &::before {
                top:em(35);
                left: 0;
                width:em(70);
                @include media-breakpoint-down(sm) {
                    top:em(40);
                }
            }
        }
    }
    .item2 {
        width: em(545);
        max-width: 545px;
        margin-top:em(-30);
        position: relative;
        z-index: 5;
        .ruche__container--item--text {
            top:em(5);
            transform: translateX(100%);
            padding-left:em(160);
            text-align: left;
            @include media-breakpoint-down(sm) {
                padding-left:em(260);
            }
            &::before {
            top:em(35);
                left: 0;
                width:em(140);
                @include media-breakpoint-down(sm) {
                    width:em(260);
                }
            }
        }
    }
    .item3 {
        width: em(545);
        max-width: 545px;
        margin-top:em(-8);
        position: relative;
        z-index: 3;
        @include media-breakpoint-up(sm) {
            &:hover {
                .img10 {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .img10 {
                opacity: 0;
                visibility: hidden;
                transition: 0.3s opacity;
            }
        }
    }
    .item4 {
        width: em(545);
        max-width: 545px;
        margin-top:em(8);
        position: relative;
        z-index: 4;
        .ruche__container--item--text {
            top: em(-8);
            transform: translateX(-100%);
            padding-right:em(120);
            text-align: right;
            z-index: 10;
            left: 0;
            @include media-breakpoint-down(sm) {
                padding-right:em(85);
            }
            &::before {
                top:em(22);
                right:  0;
                width:em(90);
            }
        }
        .libelle-number {
            @include media-breakpoint-down(sm) {
                margin-left: auto;
            }
        }
    }
    .item5 {
        width: em(545);
        max-width: 545px;
        position: relative;
        z-index: 2;
        margin-top: em(-64);
        .ruche__container--item--text {
            top: em(-7);
            transform: translateX(100%);
            padding-left:em(50);
            text-align: left;
            @include media-breakpoint-down(sm) {
                padding-left:em(70);
            }
            &::before {
                top:em(28);
                left: em(-100);
                width:em(140);
            }
            &.text2 {
                top: 42%;
                padding-left: 12.5em;
                transform: translateX(100%);
                padding-left:em(160);
                text-align: left;
                @include media-breakpoint-down(sm) {
                    padding-left:em(260);
                }
                &::before {
                top:em(35);
                    left: 0;
                    width:em(140);
                    @include media-breakpoint-down(sm) {
                        width:em(260);
                    }
                }
            }
        }
    }
    .item6 {
        width: em(637);
        max-width: 637px;
        position: relative;
        z-index: 1;
        margin-top: em(-120);
        margin-left: -5%;
        .libelle-number {
            @include media-breakpoint-down(sm) {
                margin-left: auto;
            }
        }
        .ruche__container--item--text {
            top: em(70);
            transform: translateX(-100%);
            padding-right:em(200);
            text-align: right;
            z-index: 10;
            left: em(140);
            width: rem(900);
            @include media-breakpoint-down(sm) {
                padding-right:em(180);
            }
            &::before {
                top:em(30);
                right:  em(-50);
                width:em(240);
            }
        }
    }
    .item7 {
        width: em(240);
        max-width: 240px;
        position: absolute;
        top: 30%;
        left: -40%;
        z-index: 11;
        .ruche__container--item--text {
            top: 85%;
            transform: translateX(-100%);
            text-align: right;
            z-index: 11;
            
            @include media-breakpoint-up(sm) {
                width: em(370);
                padding-right:em(160);
                right: em(-120);
            }
            @include media-breakpoint-down(sm) {
                right: em(35);
                .libelle-number {
                    margin-top: em(14);
                    margin-left: em(-8);  
                }
            }
            &::before {
                top:em(35);
                right:em(50);
                width:em(90);
                @include media-breakpoint-down(sm) {
                    width: 1px;
                    height: em(60);
                    top: 0;
                    right:em(22);
                }
            }
        }
        div:not(.ruche__container--item--text) {
            position: relative;
            .img10 {
                position: absolute;
                left: 0;
                bottom: 0;
                transform: translate(-50%, 50%);
                width: em(131);
            }
        }
    }
}
