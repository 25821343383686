@charset "UTF-8";
/*!
 * Bootstrap v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #5CA2B3;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #E93748;
  --red: #DF5C60;
  --orange: #CF9338;
  --orange2: #ECB041;
  --yellow: #FCBE16;
  --green: #697F2A;
  --teal: #20c997;
  --cyan: #192c47;
  --white: #fff;
  --gray: #9F9F9F;
  --gray-dark: #343a40;
  --primary: #CF9338;
  --secondary: #9F9F9F;
  --success: #697F2A;
  --info: #192c47;
  --warning: #FCBE16;
  --danger: #DF5C60;
  --light: #F5F5F5;
  --dark: #221F20;
  --breakpoint-xs: 0;
  --breakpoint-xsm: 360px;
  --breakpoint-sm: 767px;
  --breakpoint-md: 768px;
  --breakpoint-mdg: 800px;
  --breakpoint-lg: 1024px;
  --breakpoint-xgl: 1200px;
  --breakpoint-xl: 1920px;
  --font-family-sans-serif: "Open Sans", Roboto, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --font-family-monospace: "Libre Baskerville", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(34, 31, 32, 0); }

@-ms-viewport {
  width: device-width; }

article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Open Sans", Roboto, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #221F20;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

.p, p {
  margin-top: 0;
  color: #6D6E71;
  margin-bottom: 1rem;
  line-height: 1.375; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

dfn {
  font-style: italic; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #221F20;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  transition: 0.3s color; }
  a:hover {
    color: #CF9338;
    text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
  height: auto; }

svg:not(:root) {
  overflow: hidden; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #9F9F9F;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.3125rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Libre Baskerville", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-weight: 500;
  line-height: 1.2;
  color: #CF9338; }

h1, .h1 {
  font-size: 4.6875rem; }

h2, .h2 {
  font-size: 3.125rem; }

h3, .h3 {
  font-size: 2.5rem; }

h4, .h4 {
  font-size: 2.1875rem; }

h5, .h5 {
  font-size: 1.5625rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 7.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(34, 31, 32, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #9F9F9F; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #D3D2D2;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #9F9F9F; }

code,
kbd,
pre,
samp {
  font-family: "Libre Baskerville", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

code {
  font-size: 87.5%;
  color: #E93748;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 1024px) {
    .container {
      max-width: 1688px; } }

.container-fluid {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -24px;
  margin-left: -24px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-xsm-1, .col-xsm-2, .col-xsm-3, .col-xsm-4, .col-xsm-5, .col-xsm-6, .col-xsm-7, .col-xsm-8, .col-xsm-9, .col-xsm-10, .col-xsm-11, .col-xsm-12, .col-xsm,
.col-xsm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, header [class*="col-"], .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-mdg-1, .col-mdg-2, .col-mdg-3, .col-mdg-4, .col-mdg-5, .col-mdg-6, .col-mdg-7, .col-mdg-8, .col-mdg-9, .col-mdg-10, .col-mdg-11, .col-mdg-12, .col-mdg,
.col-mdg-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xgl-1, .col-xgl-2, .col-xgl-3, .col-xgl-4, .col-xgl-5, .col-xgl-6, .col-xgl-7, .col-xgl-8, .col-xgl-9, .col-xgl-10, .col-xgl-11, .col-xgl-12, .col-xgl,
.col-xgl-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 24px;
  padding-left: 24px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 360px) {
  .col-xsm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xsm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xsm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xsm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xsm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xsm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xsm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xsm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xsm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xsm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xsm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xsm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xsm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xsm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xsm-first {
    order: -1; }
  .order-xsm-last {
    order: 13; }
  .order-xsm-0 {
    order: 0; }
  .order-xsm-1 {
    order: 1; }
  .order-xsm-2 {
    order: 2; }
  .order-xsm-3 {
    order: 3; }
  .order-xsm-4 {
    order: 4; }
  .order-xsm-5 {
    order: 5; }
  .order-xsm-6 {
    order: 6; }
  .order-xsm-7 {
    order: 7; }
  .order-xsm-8 {
    order: 8; }
  .order-xsm-9 {
    order: 9; }
  .order-xsm-10 {
    order: 10; }
  .order-xsm-11 {
    order: 11; }
  .order-xsm-12 {
    order: 12; }
  .offset-xsm-0 {
    margin-left: 0; }
  .offset-xsm-1 {
    margin-left: 8.33333%; }
  .offset-xsm-2 {
    margin-left: 16.66667%; }
  .offset-xsm-3 {
    margin-left: 25%; }
  .offset-xsm-4 {
    margin-left: 33.33333%; }
  .offset-xsm-5 {
    margin-left: 41.66667%; }
  .offset-xsm-6 {
    margin-left: 50%; }
  .offset-xsm-7 {
    margin-left: 58.33333%; }
  .offset-xsm-8 {
    margin-left: 66.66667%; }
  .offset-xsm-9 {
    margin-left: 75%; }
  .offset-xsm-10 {
    margin-left: 83.33333%; }
  .offset-xsm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 767px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4, header [class*="col-"] {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 800px) {
  .col-mdg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-mdg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-mdg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-mdg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-mdg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-mdg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-mdg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-mdg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-mdg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-mdg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-mdg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-mdg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-mdg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-mdg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-mdg-first {
    order: -1; }
  .order-mdg-last {
    order: 13; }
  .order-mdg-0 {
    order: 0; }
  .order-mdg-1 {
    order: 1; }
  .order-mdg-2 {
    order: 2; }
  .order-mdg-3 {
    order: 3; }
  .order-mdg-4 {
    order: 4; }
  .order-mdg-5 {
    order: 5; }
  .order-mdg-6 {
    order: 6; }
  .order-mdg-7 {
    order: 7; }
  .order-mdg-8 {
    order: 8; }
  .order-mdg-9 {
    order: 9; }
  .order-mdg-10 {
    order: 10; }
  .order-mdg-11 {
    order: 11; }
  .order-mdg-12 {
    order: 12; }
  .offset-mdg-0 {
    margin-left: 0; }
  .offset-mdg-1 {
    margin-left: 8.33333%; }
  .offset-mdg-2 {
    margin-left: 16.66667%; }
  .offset-mdg-3 {
    margin-left: 25%; }
  .offset-mdg-4 {
    margin-left: 33.33333%; }
  .offset-mdg-5 {
    margin-left: 41.66667%; }
  .offset-mdg-6 {
    margin-left: 50%; }
  .offset-mdg-7 {
    margin-left: 58.33333%; }
  .offset-mdg-8 {
    margin-left: 66.66667%; }
  .offset-mdg-9 {
    margin-left: 75%; }
  .offset-mdg-10 {
    margin-left: 83.33333%; }
  .offset-mdg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xgl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xgl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xgl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xgl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xgl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xgl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xgl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xgl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xgl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xgl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xgl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xgl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xgl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xgl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xgl-first {
    order: -1; }
  .order-xgl-last {
    order: 13; }
  .order-xgl-0 {
    order: 0; }
  .order-xgl-1 {
    order: 1; }
  .order-xgl-2 {
    order: 2; }
  .order-xgl-3 {
    order: 3; }
  .order-xgl-4 {
    order: 4; }
  .order-xgl-5 {
    order: 5; }
  .order-xgl-6 {
    order: 6; }
  .order-xgl-7 {
    order: 7; }
  .order-xgl-8 {
    order: 8; }
  .order-xgl-9 {
    order: 9; }
  .order-xgl-10 {
    order: 10; }
  .order-xgl-11 {
    order: 11; }
  .order-xgl-12 {
    order: 12; }
  .offset-xgl-0 {
    margin-left: 0; }
  .offset-xgl-1 {
    margin-left: 8.33333%; }
  .offset-xgl-2 {
    margin-left: 16.66667%; }
  .offset-xgl-3 {
    margin-left: 25%; }
  .offset-xgl-4 {
    margin-left: 33.33333%; }
  .offset-xgl-5 {
    margin-left: 41.66667%; }
  .offset-xgl-6 {
    margin-left: 50%; }
  .offset-xgl-7 {
    margin-left: 58.33333%; }
  .offset-xgl-8 {
    margin-left: 66.66667%; }
  .offset-xgl-9 {
    margin-left: 75%; }
  .offset-xgl-10 {
    margin-left: 83.33333%; }
  .offset-xgl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1920px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #D3D2D2; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #D3D2D2; }
  .table tbody + tbody {
    border-top: 2px solid #D3D2D2; }
  .table .table {
    background-color: #fff; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #D3D2D2; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #D3D2D2; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(34, 31, 32, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(34, 31, 32, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f2e1c7; }

.table-hover .table-primary:hover {
  background-color: #edd6b2; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #edd6b2; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e4e4e4; }

.table-hover .table-secondary:hover {
  background-color: #d7d7d7; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #d7d7d7; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d5dbc3; }

.table-hover .table-success:hover {
  background-color: #cad1b3; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #cad1b3; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bfc4cb; }

.table-hover .table-info:hover {
  background-color: #b1b7c0; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #b1b7c0; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #feedbe; }

.table-hover .table-warning:hover {
  background-color: #fee6a5; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fee6a5; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f6d1d2; }

.table-hover .table-danger:hover {
  background-color: #f2bcbd; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f2bcbd; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc; }

.table-hover .table-light:hover {
  background-color: #efefef; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #efefef; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c0c1; }

.table-hover .table-dark:hover {
  background-color: #b4b3b4; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4b3b4; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(34, 31, 32, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(21, 19, 19, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(21, 19, 19, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #6D6E71;
  background-color: #EBEBEB;
  border-color: #D3D2D2; }

.table-dark {
  color: #fff;
  background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 359.98px) {
  .table-responsive-xsm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xsm > .table-bordered {
      border: 0; } }

@media (max-width: 766.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 799.98px) {
  .table-responsive-mdg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-mdg > .table-bordered {
      border: 0; } }

@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xgl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xgl > .table-bordered {
      border: 0; } }

@media (max-width: 1919.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  padding: 0.6875rem 1.625rem;
  font-size: 1rem;
  line-height: 1.2;
  color: #6D6E71;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #D3D2D2;
  outline: none;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control::placeholder {
    color: #6D6E71;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #EBEBEB;
    opacity: 1; }

select.form-control:not([size]):not([multiple]) {
  height: calc(2.575rem + 2px); }

select.form-control:focus::-ms-value {
  color: #6D6E71;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.6875rem + 1px);
  padding-bottom: calc(0.6875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.2; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  margin-bottom: 0;
  line-height: 1.2;
  color: #221F20;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(1.8125rem + 2px); }

.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5; }

select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
  height: calc(2.5rem + 2px); }

.form-group {
  margin-bottom: 1.5rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #9F9F9F; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #697F2A; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(105, 127, 42, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:valid, .form-control.is-valid, .was-validated
.custom-select:valid,
.custom-select.is-valid {
  border-color: #697F2A; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #697F2A;
    box-shadow: 0 0 0 0.2rem rgba(105, 127, 42, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #697F2A; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #697F2A; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #aeca5f; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  background-color: #89a537; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(105, 127, 42, 0.25); }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #697F2A; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(105, 127, 42, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #DF5C60; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1;
  color: #fff;
  background-color: rgba(223, 92, 96, 0.8);
  border-radius: .2rem; }

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
.custom-select:invalid,
.custom-select.is-invalid {
  border-color: #DF5C60; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #DF5C60;
    box-shadow: 0 0 0 0.2rem rgba(223, 92, 96, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #DF5C60; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #DF5C60; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #f4c7c8; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  background-color: #e7878a; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(223, 92, 96, 0.25); }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #DF5C60; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  box-shadow: 0 0 0 0.2rem rgba(223, 92, 96, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 767px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.75rem 1.5625rem 0.5625rem;
  font-size: 1.25rem;
  line-height: 1.35;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-radius: 1.5625rem; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:before {
    content: '';
    display: inline-block;
    background-image: url(../../../medias/images/bee-btn-icon-orange.svg);
    background-repeat: no-repeat;
    width: 1.875rem;
    height: 1.6875rem;
    background-size: cover;
    margin-right: 0.5rem;
    vertical-align: middle; }
  .btn:hover:before {
    background-image: url(../../../medias/images/bee-btn-icon-white.svg); }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0; }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #CF9338;
  border-color: #CF9338; }
  .btn-primary:hover {
    color: #fff;
    background-color: #CF9338;
    border-color: #CF9338; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #212529;
    background-color: #CF9338;
    border-color: #CF9338; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ab7729;
    border-color: #a17027; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(207, 147, 56, 0.5); }

.btn-secondary {
  color: #9F9F9F;
  border-color: #9F9F9F; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #9F9F9F;
    border-color: #9F9F9F; }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #9F9F9F;
    border-color: #9F9F9F; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #868686;
    border-color: #7f7f7f; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(159, 159, 159, 0.5); }

.btn-success {
  color: #697F2A;
  border-color: #697F2A; }
  .btn-success:hover {
    color: #fff;
    background-color: #697F2A;
    border-color: #697F2A; }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #697F2A;
    border-color: #697F2A; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #49591d;
    border-color: #414f1a; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 127, 42, 0.5); }

.btn-info {
  color: #192c47;
  border-color: #192c47; }
  .btn-info:hover {
    color: #fff;
    background-color: #192c47;
    border-color: #192c47; }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #192c47;
    border-color: #192c47; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0c1521;
    border-color: #080f18; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(25, 44, 71, 0.5); }

.btn-warning {
  color: #FCBE16;
  border-color: #FCBE16; }
  .btn-warning:hover {
    color: #212529;
    background-color: #FCBE16;
    border-color: #FCBE16; }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #FCBE16;
    border-color: #FCBE16; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #dca203;
    border-color: #d09803; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 190, 22, 0.5); }

.btn-danger {
  color: #DF5C60;
  border-color: #DF5C60; }
  .btn-danger:hover {
    color: #fff;
    background-color: #DF5C60;
    border-color: #DF5C60; }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #DF5C60;
    border-color: #DF5C60; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #d73136;
    border-color: #d2292e; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 92, 96, 0.5); }

.btn-light {
  color: #F5F5F5;
  border-color: #F5F5F5; }
  .btn-light:hover {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: gainsboro;
    border-color: #d5d5d5; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5); }

.btn-dark {
  color: #221F20;
  border-color: #221F20; }
  .btn-dark:hover {
    color: #fff;
    background-color: #221F20;
    border-color: #221F20; }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #221F20;
    border-color: #221F20; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #070707;
    border-color: #010101; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 31, 32, 0.5); }

.btn-outline-primary {
  color: #CF9338;
  background-color: transparent;
  background-image: none;
  border-color: #CF9338; }
  .btn-outline-primary:hover {
    color: #212529;
    background-color: #CF9338;
    border-color: #CF9338; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(207, 147, 56, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #CF9338;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #212529;
    background-color: #CF9338;
    border-color: #CF9338; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(207, 147, 56, 0.5); }

.btn-outline-secondary {
  color: #9F9F9F;
  background-color: transparent;
  background-image: none;
  border-color: #9F9F9F; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #9F9F9F;
    border-color: #9F9F9F; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(159, 159, 159, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #9F9F9F;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #9F9F9F;
    border-color: #9F9F9F; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(159, 159, 159, 0.5); }

.btn-outline-success {
  color: #697F2A;
  background-color: transparent;
  background-image: none;
  border-color: #697F2A; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #697F2A;
    border-color: #697F2A; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(105, 127, 42, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #697F2A;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #697F2A;
    border-color: #697F2A; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(105, 127, 42, 0.5); }

.btn-outline-info {
  color: #192c47;
  background-color: transparent;
  background-image: none;
  border-color: #192c47; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #192c47;
    border-color: #192c47; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(25, 44, 71, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #192c47;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #192c47;
    border-color: #192c47; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(25, 44, 71, 0.5); }

.btn-outline-warning {
  color: #FCBE16;
  background-color: transparent;
  background-image: none;
  border-color: #FCBE16; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #FCBE16;
    border-color: #FCBE16; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(252, 190, 22, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #FCBE16;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #FCBE16;
    border-color: #FCBE16; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(252, 190, 22, 0.5); }

.btn-outline-danger {
  color: #DF5C60;
  background-color: transparent;
  background-image: none;
  border-color: #DF5C60; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #DF5C60;
    border-color: #DF5C60; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(223, 92, 96, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #DF5C60;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #DF5C60;
    border-color: #DF5C60; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(223, 92, 96, 0.5); }

.btn-outline-light {
  color: #F5F5F5;
  background-color: transparent;
  background-image: none;
  border-color: #F5F5F5; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #F5F5F5;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #F5F5F5;
    border-color: #F5F5F5; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 245, 245, 0.5); }

.btn-outline-dark {
  color: #221F20;
  background-color: transparent;
  background-image: none;
  border-color: #221F20; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #221F20;
    border-color: #221F20; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 31, 32, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #221F20;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #221F20;
    border-color: #221F20; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 31, 32, 0.5); }

.btn-link {
  font-weight: 400;
  color: #221F20;
  background-color: transparent; }
  .btn-link:hover {
    color: #CF9338;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #9F9F9F;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.75rem 1rem 0.5625rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.75rem 0.5rem 0.5625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #221F20;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 31, 32, 0.15); }

.dropdown-menu-right {
  right: 0;
  left: auto; }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  width: 0;
  height: 0;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #EBEBEB; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #F5F5F5; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #CF9338; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #9F9F9F;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #9F9F9F;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:first-child {
  margin-left: 0; }

.dropdown-toggle-split {
  padding-right: 1.17188rem;
  padding-left: 1.17188rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control:focus,
    .input-group > .custom-select:focus,
    .input-group > .custom-file:focus {
      z-index: 3; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6875rem 1.625rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #6D6E71;
  text-align: center;
  white-space: nowrap;
  background-color: #EBEBEB;
  border: 1px solid #D3D2D2; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.2rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff; }
  .custom-control-input:active ~ .custom-control-label::before {
    color: #fff; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #9F9F9F; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #EBEBEB; }

.custom-control-label {
  margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 4px;
    left: 0;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    border: 1px solid #D3D2D2;
    background-color: #fff; }
  .custom-control-label::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 1.25rem;
    height: 1.125rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='0' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  background-color: #CF9338; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(207, 147, 56, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(207, 147, 56, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #CF9338; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(207, 147, 56, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.575rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.2;
  color: #6D6E71;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
  background-size: 8px 10px;
  border: 1px solid #D3D2D2;
  border-radius: 0;
  appearance: none; }
  .custom-select:focus {
    border-color: #e8cb9f;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(34, 31, 32, 0.075), 0 0 5px rgba(232, 203, 159, 0.5); }
    .custom-select:focus::-ms-value {
      color: #6D6E71;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #9F9F9F;
    background-color: #EBEBEB; }
  .custom-select::-ms-expand {
    opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 75%; }

.custom-select-lg {
  height: calc(2.5rem + 2px);
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 125%; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.575rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.575rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #e8cb9f;
    box-shadow: 0 0 0 0.2rem rgba(207, 147, 56, 0.25); }
    .custom-file-input:focus ~ .custom-file-label::after {
      border-color: #e8cb9f; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.575rem + 2px);
  padding: 0.6875rem 1.625rem;
  line-height: 1.2;
  color: #6D6E71;
  background-color: #fff;
  border: 1px solid #D3D2D2; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.575rem + 2px) - 1px * 2);
    padding: 0.6875rem 1.625rem;
    line-height: 1.2;
    color: #6D6E71;
    content: "Browse";
    background-color: #EBEBEB;
    border-left: 1px solid #D3D2D2; }

.custom-range {
  width: 100%;
  padding-left: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #CF9338;
    border: 0;
    appearance: none; }
    .custom-range::-webkit-slider-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(207, 147, 56, 0.25); }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #f2e1c8; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #D3D2D2;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #CF9338;
    border: 0;
    appearance: none; }
    .custom-range::-moz-range-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(207, 147, 56, 0.25); }
    .custom-range::-moz-range-thumb:active {
      background-color: #f2e1c8; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #D3D2D2;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #CF9338;
    border: 0;
    appearance: none; }
    .custom-range::-ms-thumb:focus {
      outline: none;
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(207, 147, 56, 0.25); }
    .custom-range::-ms-thumb:active {
      background-color: #f2e1c8; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #D3D2D2; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #D3D2D2; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #221F20; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #9F9F9F; }

.nav-tabs {
  border-bottom: 1px solid #D3D2D2; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #EBEBEB #EBEBEB #D3D2D2; }
    .nav-tabs .nav-link.disabled {
      color: #9F9F9F;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #6D6E71;
    background-color: #fff;
    border-color: #D3D2D2 #D3D2D2 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px; }

.nav-pills {
  justify-content: center; }
  @media (min-width: 767px) {
    .nav-pills .nav-item {
      padding-left: 3.3125rem;
      padding-right: 3.3125rem; } }
  @media (max-width: 767.98px) {
    .nav-pills .nav-item {
      flex-direction: column;
      width: 100%;
      margin-bottom: 1.875rem; } }
  .nav-pills .nav-link {
    font-size: 1.375rem;
    padding-left: 0;
    padding-right: 0;
    font-family: "Libre Baskerville", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }
    @media (min-width: 767px) {
      .nav-pills .nav-link {
        position: relative;
        margin-bottom: 1rem; }
        .nav-pills .nav-link:before {
          content: '';
          position: absolute;
          display: block;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background-color: #CF9338;
          transform: scaleX(0);
          transition: 0.3s all; } }
    @media (max-width: 767.98px) {
      .nav-pills .nav-link {
        display: flex;
        align-content: center;
        justify-content: center;
        padding: 0.75rem 1.25rem 0.6875rem;
        height: 3.125rem;
        border-radius: 1.5625rem;
        border: 1px solid #221F20;
        width: 100%;
        font-size: 1.25rem;
        line-height: 1.35; } }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #CF9338; }
    @media (min-width: 767px) {
      .nav-pills .nav-link.active::before,
      .nav-pills .show > .nav-link::before {
        transform: scaleX(1); } }
    @media (max-width: 767.98px) {
      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        border-color: #CF9338; } }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 24px;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  width: 100%;
  justify-content: space-between; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0;
  height: 40px;
  width: 40px;
  line-height: 1;
  background-color: transparent;
  border: 0 none; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .navbar-toggler:focus {
    outline: none; }
  .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
    background-color: transparent; }
    .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
      transform: rotate(45deg);
      top: 0; }
    .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
      width: inherit;
      transform: rotate(-45deg);
      top: 0; }

.navbar-toggler-icon {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 2px;
  vertical-align: middle;
  content: "";
  background-color: #221F20; }
  .navbar-toggler-icon:before {
    content: '';
    position: absolute;
    top: -15px;
    height: inherit;
    background-color: #221F20;
    width: inherit;
    right: 0;
    transform: rotate(0deg);
    transition: 0.3s all; }
  .navbar-toggler-icon:after {
    content: '';
    position: absolute;
    top: 15px;
    right: 0;
    height: inherit;
    width: inherit;
    background-color: #221F20;
    transform: rotate(0deg);
    transition: 0.3s all; }

@media (max-width: 359.98px) {
  .navbar-expand-xsm > .container,
  .navbar-expand-xsm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 360px) {
  .navbar-expand-xsm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xsm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xsm .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-xsm > .container,
    .navbar-expand-xsm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xsm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xsm .navbar-toggler {
      display: none; } }

@media (max-width: 766.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 767px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 799.98px) {
  .navbar-expand-mdg > .container,
  .navbar-expand-mdg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 800px) {
  .navbar-expand-mdg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-mdg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-mdg .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-mdg > .container,
    .navbar-expand-mdg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-mdg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-mdg .navbar-toggler {
      display: none; } }

@media (max-width: 1023.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xgl > .container,
  .navbar-expand-xgl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xgl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xgl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xgl .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-xgl > .container,
    .navbar-expand-xgl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xgl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xgl .navbar-toggler {
      display: none; } }

@media (max-width: 1919.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1920px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(34, 31, 32, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(34, 31, 32, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(34, 31, 32, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(34, 31, 32, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(34, 31, 32, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(34, 31, 32, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(34, 31, 32, 0.5);
  border-color: rgba(34, 31, 32, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(34, 31, 32, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-light .navbar-text {
  color: rgba(34, 31, 32, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(34, 31, 32, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(34, 31, 32, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(34, 31, 32, 0.125); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(34, 31, 32, 0.03);
  border-bottom: 1px solid rgba(34, 31, 32, 0.125); }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(34, 31, 32, 0.03);
  border-top: 1px solid rgba(34, 31, 32, 0.125); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%; }

.card-img-top {
  width: 100%; }

.card-img-bottom {
  width: 100%; }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 24px; }
  @media (min-width: 767px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -24px;
      margin-left: -24px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 24px;
        margin-bottom: 0;
        margin-left: 24px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 24px; }
  @media (min-width: 767px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 767px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 20px;
  margin-top: 25px;
  margin-bottom: 1rem;
  list-style: none; }

.breadcrumb-item {
  text-transform: uppercase; }
  .breadcrumb-item a {
    color: #221F20;
    transition: 0.3s color; }
    .breadcrumb-item a:hover {
      color: #9F9F9F; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.375rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.375rem;
      color: #221F20;
      content: ">"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  padding-left: 0;
  list-style: none; }

.page-link {
  position: relative;
  display: block;
  padding: 0 0.75rem;
  line-height: 1.25;
  color: #221F20;
  background-color: #fff;
  border-right: 1px solid #808080;
  font-size: 18px; }
  .page-link:hover {
    z-index: 2;
    color: #CF9338;
    text-decoration: none; }
  .page-link:focus {
    z-index: 2; }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 5px;
  padding-right: 0; }

.page-item:last-child .page-link {
  color: #192c47; }

.page-item.active .page-link {
  z-index: 1;
  color: #192c47; }

.page-item.disabled .page-link {
  color: #192c47;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border: 0 none;
  font-size: 18px; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em; }

.badge-primary {
  color: #212529;
  background-color: #CF9338; }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #ab7729; }

.badge-secondary {
  color: #212529;
  background-color: #9F9F9F; }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #868686; }

.badge-success {
  color: #fff;
  background-color: #697F2A; }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #49591d; }

.badge-info {
  color: #fff;
  background-color: #192c47; }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #0c1521; }

.badge-warning {
  color: #212529;
  background-color: #FCBE16; }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: #dca203; }

.badge-danger {
  color: #fff;
  background-color: #DF5C60; }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #d73136; }

.badge-light {
  color: #212529;
  background-color: #F5F5F5; }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #212529;
    text-decoration: none;
    background-color: gainsboro; }

.badge-dark {
  color: #fff;
  background-color: #221F20; }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #070707; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #EBEBEB; }
  @media (min-width: 767px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #7c5b2c;
  background-color: #f5e9d7;
  border-color: #f2e1c7; }
  .alert-primary hr {
    border-top-color: #edd6b2; }
  .alert-primary .alert-link {
    color: #563f1f; }

.alert-secondary {
  color: #636262;
  background-color: #ececec;
  border-color: #e4e4e4; }
  .alert-secondary hr {
    border-top-color: #d7d7d7; }
  .alert-secondary .alert-link {
    color: #494949; }

.alert-success {
  color: #475125;
  background-color: #e1e5d4;
  border-color: #d5dbc3; }
  .alert-success hr {
    border-top-color: #cad1b3; }
  .alert-success .alert-link {
    color: #282e15; }

.alert-info {
  color: #1d2634;
  background-color: #d1d5da;
  border-color: #bfc4cb; }
  .alert-info hr {
    border-top-color: #b1b7c0; }
  .alert-info .alert-link {
    color: #0b0e13; }

.alert-warning {
  color: #93721b;
  background-color: #fef2d0;
  border-color: #feedbe; }
  .alert-warning hr {
    border-top-color: #fee6a5; }
  .alert-warning .alert-link {
    color: #685113; }

.alert-danger {
  color: #843f41;
  background-color: #f9dedf;
  border-color: #f6d1d2; }
  .alert-danger hr {
    border-top-color: #f2bcbd; }
  .alert-danger .alert-link {
    color: #612f30; }

.alert-light {
  color: #908e8f;
  background-color: #fdfdfd;
  border-color: #fcfcfc; }
  .alert-light hr {
    border-top-color: #efefef; }
  .alert-light .alert-link {
    color: #777476; }

.alert-dark {
  color: #221f20;
  background-color: #d3d2d2;
  border-color: #c1c0c1; }
  .alert-dark hr {
    border-top-color: #b4b3b4; }
  .alert-dark .alert-link {
    color: #070707; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #EBEBEB; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #CF9338;
  transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #6D6E71;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #6D6E71;
    text-decoration: none;
    background-color: #F5F5F5; }
  .list-group-item-action:active {
    color: #221F20;
    background-color: #EBEBEB; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(34, 31, 32, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #9F9F9F;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #CF9338;
    border-color: #CF9338; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom: 0; }

.list-group-item-primary {
  color: #7c5b2c;
  background-color: #f2e1c7; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #7c5b2c;
    background-color: #edd6b2; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #7c5b2c;
    border-color: #7c5b2c; }

.list-group-item-secondary {
  color: #636262;
  background-color: #e4e4e4; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #636262;
    background-color: #d7d7d7; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #636262;
    border-color: #636262; }

.list-group-item-success {
  color: #475125;
  background-color: #d5dbc3; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #475125;
    background-color: #cad1b3; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #475125;
    border-color: #475125; }

.list-group-item-info {
  color: #1d2634;
  background-color: #bfc4cb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #1d2634;
    background-color: #b1b7c0; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #1d2634;
    border-color: #1d2634; }

.list-group-item-warning {
  color: #93721b;
  background-color: #feedbe; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #93721b;
    background-color: #fee6a5; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #93721b;
    border-color: #93721b; }

.list-group-item-danger {
  color: #843f41;
  background-color: #f6d1d2; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #843f41;
    background-color: #f2bcbd; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #843f41;
    border-color: #843f41; }

.list-group-item-light {
  color: #908e8f;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #908e8f;
    background-color: #efefef; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #908e8f;
    border-color: #908e8f; }

.list-group-item-dark {
  color: #221f20;
  background-color: #c1c0c1; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #221f20;
    background-color: #b4b3b4; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #221f20;
    border-color: #221f20; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #221F20;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover, .close:focus {
    color: #221F20;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }

.modal-open {
  overflow: hidden; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
    @media screen and (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (0.5rem * 2)); }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 31, 32, 0.2);
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #221F20; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: 1px solid #EBEBEB; }
  .modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.2; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #EBEBEB; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 767px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1024px) {
  .modal-lg {
    max-width: 800px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Open Sans", Roboto, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #221F20; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #221F20; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #221F20; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #221F20; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #221F20; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Open Sans", Roboto, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(34, 31, 32, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(34, 31, 32, 0.25); }
  
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(34, 31, 32, 0.25); }
  
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(34, 31, 32, 0.25); }
  
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(34, 31, 32, 0.25); }
  
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: #CF9338;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #221F20; }

.carousel {
  position: relative; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  transition: transform 0.6s ease;
  backface-visibility: hidden;
  perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next,
.carousel-item-prev {
  position: absolute;
  top: 0; }

.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

.carousel-item-next,
.active.carousel-item-right {
  transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

.carousel-item-prev,
.active.carousel-item-left {
  transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 10px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: rgba(255, 255, 255, 0.5); }
    .carousel-indicators li::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
    .carousel-indicators li::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: ""; }
  .carousel-indicators .active {
    background-color: #fff; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #CF9338 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #ab7729 !important; }

.bg-secondary {
  background-color: #9F9F9F !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #868686 !important; }

.bg-success {
  background-color: #697F2A !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #49591d !important; }

.bg-info {
  background-color: #192c47 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0c1521 !important; }

.bg-warning {
  background-color: #FCBE16 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #dca203 !important; }

.bg-danger {
  background-color: #DF5C60 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d73136 !important; }

.bg-light {
  background-color: #F5F5F5 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: gainsboro !important; }

.bg-dark {
  background-color: #221F20 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #070707 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #D3D2D2 !important; }

.border-top {
  border-top: 1px solid #D3D2D2 !important; }

.border-right {
  border-right: 1px solid #D3D2D2 !important; }

.border-bottom {
  border-bottom: 1px solid #D3D2D2 !important; }

.border-left {
  border-left: 1px solid #D3D2D2 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #CF9338 !important; }

.border-secondary {
  border-color: #9F9F9F !important; }

.border-success {
  border-color: #697F2A !important; }

.border-info {
  border-color: #192c47 !important; }

.border-warning {
  border-color: #FCBE16 !important; }

.border-danger {
  border-color: #DF5C60 !important; }

.border-light {
  border-color: #F5F5F5 !important; }

.border-dark {
  border-color: #221F20 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 360px) {
  .d-xsm-none {
    display: none !important; }
  .d-xsm-inline {
    display: inline !important; }
  .d-xsm-inline-block {
    display: inline-block !important; }
  .d-xsm-block {
    display: block !important; }
  .d-xsm-table {
    display: table !important; }
  .d-xsm-table-row {
    display: table-row !important; }
  .d-xsm-table-cell {
    display: table-cell !important; }
  .d-xsm-flex {
    display: flex !important; }
  .d-xsm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 767px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 800px) {
  .d-mdg-none {
    display: none !important; }
  .d-mdg-inline {
    display: inline !important; }
  .d-mdg-inline-block {
    display: inline-block !important; }
  .d-mdg-block {
    display: block !important; }
  .d-mdg-table {
    display: table !important; }
  .d-mdg-table-row {
    display: table-row !important; }
  .d-mdg-table-cell {
    display: table-cell !important; }
  .d-mdg-flex {
    display: flex !important; }
  .d-mdg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xgl-none {
    display: none !important; }
  .d-xgl-inline {
    display: inline !important; }
  .d-xgl-inline-block {
    display: inline-block !important; }
  .d-xgl-block {
    display: block !important; }
  .d-xgl-table {
    display: table !important; }
  .d-xgl-table-row {
    display: table-row !important; }
  .d-xgl-table-cell {
    display: table-cell !important; }
  .d-xgl-flex {
    display: flex !important; }
  .d-xgl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1920px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 360px) {
  .flex-xsm-row {
    flex-direction: row !important; }
  .flex-xsm-column {
    flex-direction: column !important; }
  .flex-xsm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xsm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xsm-wrap {
    flex-wrap: wrap !important; }
  .flex-xsm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xsm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xsm-fill {
    flex: 1 1 auto !important; }
  .flex-xsm-grow-0 {
    flex-grow: 0 !important; }
  .flex-xsm-grow-1 {
    flex-grow: 1 !important; }
  .flex-xsm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xsm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xsm-start {
    justify-content: flex-start !important; }
  .justify-content-xsm-end {
    justify-content: flex-end !important; }
  .justify-content-xsm-center {
    justify-content: center !important; }
  .justify-content-xsm-between {
    justify-content: space-between !important; }
  .justify-content-xsm-around {
    justify-content: space-around !important; }
  .align-items-xsm-start {
    align-items: flex-start !important; }
  .align-items-xsm-end {
    align-items: flex-end !important; }
  .align-items-xsm-center {
    align-items: center !important; }
  .align-items-xsm-baseline {
    align-items: baseline !important; }
  .align-items-xsm-stretch {
    align-items: stretch !important; }
  .align-content-xsm-start {
    align-content: flex-start !important; }
  .align-content-xsm-end {
    align-content: flex-end !important; }
  .align-content-xsm-center {
    align-content: center !important; }
  .align-content-xsm-between {
    align-content: space-between !important; }
  .align-content-xsm-around {
    align-content: space-around !important; }
  .align-content-xsm-stretch {
    align-content: stretch !important; }
  .align-self-xsm-auto {
    align-self: auto !important; }
  .align-self-xsm-start {
    align-self: flex-start !important; }
  .align-self-xsm-end {
    align-self: flex-end !important; }
  .align-self-xsm-center {
    align-self: center !important; }
  .align-self-xsm-baseline {
    align-self: baseline !important; }
  .align-self-xsm-stretch {
    align-self: stretch !important; } }

@media (min-width: 767px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 800px) {
  .flex-mdg-row {
    flex-direction: row !important; }
  .flex-mdg-column {
    flex-direction: column !important; }
  .flex-mdg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-mdg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-mdg-wrap {
    flex-wrap: wrap !important; }
  .flex-mdg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-mdg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-mdg-fill {
    flex: 1 1 auto !important; }
  .flex-mdg-grow-0 {
    flex-grow: 0 !important; }
  .flex-mdg-grow-1 {
    flex-grow: 1 !important; }
  .flex-mdg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-mdg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-mdg-start {
    justify-content: flex-start !important; }
  .justify-content-mdg-end {
    justify-content: flex-end !important; }
  .justify-content-mdg-center {
    justify-content: center !important; }
  .justify-content-mdg-between {
    justify-content: space-between !important; }
  .justify-content-mdg-around {
    justify-content: space-around !important; }
  .align-items-mdg-start {
    align-items: flex-start !important; }
  .align-items-mdg-end {
    align-items: flex-end !important; }
  .align-items-mdg-center {
    align-items: center !important; }
  .align-items-mdg-baseline {
    align-items: baseline !important; }
  .align-items-mdg-stretch {
    align-items: stretch !important; }
  .align-content-mdg-start {
    align-content: flex-start !important; }
  .align-content-mdg-end {
    align-content: flex-end !important; }
  .align-content-mdg-center {
    align-content: center !important; }
  .align-content-mdg-between {
    align-content: space-between !important; }
  .align-content-mdg-around {
    align-content: space-around !important; }
  .align-content-mdg-stretch {
    align-content: stretch !important; }
  .align-self-mdg-auto {
    align-self: auto !important; }
  .align-self-mdg-start {
    align-self: flex-start !important; }
  .align-self-mdg-end {
    align-self: flex-end !important; }
  .align-self-mdg-center {
    align-self: center !important; }
  .align-self-mdg-baseline {
    align-self: baseline !important; }
  .align-self-mdg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xgl-row {
    flex-direction: row !important; }
  .flex-xgl-column {
    flex-direction: column !important; }
  .flex-xgl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xgl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xgl-wrap {
    flex-wrap: wrap !important; }
  .flex-xgl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xgl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xgl-fill {
    flex: 1 1 auto !important; }
  .flex-xgl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xgl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xgl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xgl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xgl-start {
    justify-content: flex-start !important; }
  .justify-content-xgl-end {
    justify-content: flex-end !important; }
  .justify-content-xgl-center {
    justify-content: center !important; }
  .justify-content-xgl-between {
    justify-content: space-between !important; }
  .justify-content-xgl-around {
    justify-content: space-around !important; }
  .align-items-xgl-start {
    align-items: flex-start !important; }
  .align-items-xgl-end {
    align-items: flex-end !important; }
  .align-items-xgl-center {
    align-items: center !important; }
  .align-items-xgl-baseline {
    align-items: baseline !important; }
  .align-items-xgl-stretch {
    align-items: stretch !important; }
  .align-content-xgl-start {
    align-content: flex-start !important; }
  .align-content-xgl-end {
    align-content: flex-end !important; }
  .align-content-xgl-center {
    align-content: center !important; }
  .align-content-xgl-between {
    align-content: space-between !important; }
  .align-content-xgl-around {
    align-content: space-around !important; }
  .align-content-xgl-stretch {
    align-content: stretch !important; }
  .align-self-xgl-auto {
    align-self: auto !important; }
  .align-self-xgl-start {
    align-self: flex-start !important; }
  .align-self-xgl-end {
    align-self: flex-end !important; }
  .align-self-xgl-center {
    align-self: center !important; }
  .align-self-xgl-baseline {
    align-self: baseline !important; }
  .align-self-xgl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1920px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 360px) {
  .float-xsm-left {
    float: left !important; }
  .float-xsm-right {
    float: right !important; }
  .float-xsm-none {
    float: none !important; } }

@media (min-width: 767px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 800px) {
  .float-mdg-left {
    float: left !important; }
  .float-mdg-right {
    float: right !important; }
  .float-mdg-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xgl-left {
    float: left !important; }
  .float-xgl-right {
    float: right !important; }
  .float-xgl-none {
    float: none !important; } }

@media (min-width: 1920px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(34, 31, 32, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(34, 31, 32, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(34, 31, 32, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 360px) {
  .m-xsm-0 {
    margin: 0 !important; }
  .mt-xsm-0,
  .my-xsm-0 {
    margin-top: 0 !important; }
  .mr-xsm-0,
  .mx-xsm-0 {
    margin-right: 0 !important; }
  .mb-xsm-0,
  .my-xsm-0 {
    margin-bottom: 0 !important; }
  .ml-xsm-0,
  .mx-xsm-0 {
    margin-left: 0 !important; }
  .m-xsm-1 {
    margin: 0.25rem !important; }
  .mt-xsm-1,
  .my-xsm-1 {
    margin-top: 0.25rem !important; }
  .mr-xsm-1,
  .mx-xsm-1 {
    margin-right: 0.25rem !important; }
  .mb-xsm-1,
  .my-xsm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xsm-1,
  .mx-xsm-1 {
    margin-left: 0.25rem !important; }
  .m-xsm-2 {
    margin: 0.5rem !important; }
  .mt-xsm-2,
  .my-xsm-2 {
    margin-top: 0.5rem !important; }
  .mr-xsm-2,
  .mx-xsm-2 {
    margin-right: 0.5rem !important; }
  .mb-xsm-2,
  .my-xsm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xsm-2,
  .mx-xsm-2 {
    margin-left: 0.5rem !important; }
  .m-xsm-3 {
    margin: 1rem !important; }
  .mt-xsm-3,
  .my-xsm-3 {
    margin-top: 1rem !important; }
  .mr-xsm-3,
  .mx-xsm-3 {
    margin-right: 1rem !important; }
  .mb-xsm-3,
  .my-xsm-3 {
    margin-bottom: 1rem !important; }
  .ml-xsm-3,
  .mx-xsm-3 {
    margin-left: 1rem !important; }
  .m-xsm-4 {
    margin: 1.5rem !important; }
  .mt-xsm-4,
  .my-xsm-4 {
    margin-top: 1.5rem !important; }
  .mr-xsm-4,
  .mx-xsm-4 {
    margin-right: 1.5rem !important; }
  .mb-xsm-4,
  .my-xsm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xsm-4,
  .mx-xsm-4 {
    margin-left: 1.5rem !important; }
  .m-xsm-5 {
    margin: 3rem !important; }
  .mt-xsm-5,
  .my-xsm-5 {
    margin-top: 3rem !important; }
  .mr-xsm-5,
  .mx-xsm-5 {
    margin-right: 3rem !important; }
  .mb-xsm-5,
  .my-xsm-5 {
    margin-bottom: 3rem !important; }
  .ml-xsm-5,
  .mx-xsm-5 {
    margin-left: 3rem !important; }
  .p-xsm-0 {
    padding: 0 !important; }
  .pt-xsm-0,
  .py-xsm-0 {
    padding-top: 0 !important; }
  .pr-xsm-0,
  .px-xsm-0 {
    padding-right: 0 !important; }
  .pb-xsm-0,
  .py-xsm-0 {
    padding-bottom: 0 !important; }
  .pl-xsm-0,
  .px-xsm-0 {
    padding-left: 0 !important; }
  .p-xsm-1 {
    padding: 0.25rem !important; }
  .pt-xsm-1,
  .py-xsm-1 {
    padding-top: 0.25rem !important; }
  .pr-xsm-1,
  .px-xsm-1 {
    padding-right: 0.25rem !important; }
  .pb-xsm-1,
  .py-xsm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xsm-1,
  .px-xsm-1 {
    padding-left: 0.25rem !important; }
  .p-xsm-2 {
    padding: 0.5rem !important; }
  .pt-xsm-2,
  .py-xsm-2 {
    padding-top: 0.5rem !important; }
  .pr-xsm-2,
  .px-xsm-2 {
    padding-right: 0.5rem !important; }
  .pb-xsm-2,
  .py-xsm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xsm-2,
  .px-xsm-2 {
    padding-left: 0.5rem !important; }
  .p-xsm-3 {
    padding: 1rem !important; }
  .pt-xsm-3,
  .py-xsm-3 {
    padding-top: 1rem !important; }
  .pr-xsm-3,
  .px-xsm-3 {
    padding-right: 1rem !important; }
  .pb-xsm-3,
  .py-xsm-3 {
    padding-bottom: 1rem !important; }
  .pl-xsm-3,
  .px-xsm-3 {
    padding-left: 1rem !important; }
  .p-xsm-4 {
    padding: 1.5rem !important; }
  .pt-xsm-4,
  .py-xsm-4 {
    padding-top: 1.5rem !important; }
  .pr-xsm-4,
  .px-xsm-4 {
    padding-right: 1.5rem !important; }
  .pb-xsm-4,
  .py-xsm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xsm-4,
  .px-xsm-4 {
    padding-left: 1.5rem !important; }
  .p-xsm-5 {
    padding: 3rem !important; }
  .pt-xsm-5,
  .py-xsm-5 {
    padding-top: 3rem !important; }
  .pr-xsm-5,
  .px-xsm-5 {
    padding-right: 3rem !important; }
  .pb-xsm-5,
  .py-xsm-5 {
    padding-bottom: 3rem !important; }
  .pl-xsm-5,
  .px-xsm-5 {
    padding-left: 3rem !important; }
  .m-xsm-auto {
    margin: auto !important; }
  .mt-xsm-auto,
  .my-xsm-auto {
    margin-top: auto !important; }
  .mr-xsm-auto,
  .mx-xsm-auto {
    margin-right: auto !important; }
  .mb-xsm-auto,
  .my-xsm-auto {
    margin-bottom: auto !important; }
  .ml-xsm-auto,
  .mx-xsm-auto {
    margin-left: auto !important; } }

@media (min-width: 767px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 800px) {
  .m-mdg-0 {
    margin: 0 !important; }
  .mt-mdg-0,
  .my-mdg-0 {
    margin-top: 0 !important; }
  .mr-mdg-0,
  .mx-mdg-0 {
    margin-right: 0 !important; }
  .mb-mdg-0,
  .my-mdg-0 {
    margin-bottom: 0 !important; }
  .ml-mdg-0,
  .mx-mdg-0 {
    margin-left: 0 !important; }
  .m-mdg-1 {
    margin: 0.25rem !important; }
  .mt-mdg-1,
  .my-mdg-1 {
    margin-top: 0.25rem !important; }
  .mr-mdg-1,
  .mx-mdg-1 {
    margin-right: 0.25rem !important; }
  .mb-mdg-1,
  .my-mdg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-mdg-1,
  .mx-mdg-1 {
    margin-left: 0.25rem !important; }
  .m-mdg-2 {
    margin: 0.5rem !important; }
  .mt-mdg-2,
  .my-mdg-2 {
    margin-top: 0.5rem !important; }
  .mr-mdg-2,
  .mx-mdg-2 {
    margin-right: 0.5rem !important; }
  .mb-mdg-2,
  .my-mdg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-mdg-2,
  .mx-mdg-2 {
    margin-left: 0.5rem !important; }
  .m-mdg-3 {
    margin: 1rem !important; }
  .mt-mdg-3,
  .my-mdg-3 {
    margin-top: 1rem !important; }
  .mr-mdg-3,
  .mx-mdg-3 {
    margin-right: 1rem !important; }
  .mb-mdg-3,
  .my-mdg-3 {
    margin-bottom: 1rem !important; }
  .ml-mdg-3,
  .mx-mdg-3 {
    margin-left: 1rem !important; }
  .m-mdg-4 {
    margin: 1.5rem !important; }
  .mt-mdg-4,
  .my-mdg-4 {
    margin-top: 1.5rem !important; }
  .mr-mdg-4,
  .mx-mdg-4 {
    margin-right: 1.5rem !important; }
  .mb-mdg-4,
  .my-mdg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-mdg-4,
  .mx-mdg-4 {
    margin-left: 1.5rem !important; }
  .m-mdg-5 {
    margin: 3rem !important; }
  .mt-mdg-5,
  .my-mdg-5 {
    margin-top: 3rem !important; }
  .mr-mdg-5,
  .mx-mdg-5 {
    margin-right: 3rem !important; }
  .mb-mdg-5,
  .my-mdg-5 {
    margin-bottom: 3rem !important; }
  .ml-mdg-5,
  .mx-mdg-5 {
    margin-left: 3rem !important; }
  .p-mdg-0 {
    padding: 0 !important; }
  .pt-mdg-0,
  .py-mdg-0 {
    padding-top: 0 !important; }
  .pr-mdg-0,
  .px-mdg-0 {
    padding-right: 0 !important; }
  .pb-mdg-0,
  .py-mdg-0 {
    padding-bottom: 0 !important; }
  .pl-mdg-0,
  .px-mdg-0 {
    padding-left: 0 !important; }
  .p-mdg-1 {
    padding: 0.25rem !important; }
  .pt-mdg-1,
  .py-mdg-1 {
    padding-top: 0.25rem !important; }
  .pr-mdg-1,
  .px-mdg-1 {
    padding-right: 0.25rem !important; }
  .pb-mdg-1,
  .py-mdg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-mdg-1,
  .px-mdg-1 {
    padding-left: 0.25rem !important; }
  .p-mdg-2 {
    padding: 0.5rem !important; }
  .pt-mdg-2,
  .py-mdg-2 {
    padding-top: 0.5rem !important; }
  .pr-mdg-2,
  .px-mdg-2 {
    padding-right: 0.5rem !important; }
  .pb-mdg-2,
  .py-mdg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-mdg-2,
  .px-mdg-2 {
    padding-left: 0.5rem !important; }
  .p-mdg-3 {
    padding: 1rem !important; }
  .pt-mdg-3,
  .py-mdg-3 {
    padding-top: 1rem !important; }
  .pr-mdg-3,
  .px-mdg-3 {
    padding-right: 1rem !important; }
  .pb-mdg-3,
  .py-mdg-3 {
    padding-bottom: 1rem !important; }
  .pl-mdg-3,
  .px-mdg-3 {
    padding-left: 1rem !important; }
  .p-mdg-4 {
    padding: 1.5rem !important; }
  .pt-mdg-4,
  .py-mdg-4 {
    padding-top: 1.5rem !important; }
  .pr-mdg-4,
  .px-mdg-4 {
    padding-right: 1.5rem !important; }
  .pb-mdg-4,
  .py-mdg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-mdg-4,
  .px-mdg-4 {
    padding-left: 1.5rem !important; }
  .p-mdg-5 {
    padding: 3rem !important; }
  .pt-mdg-5,
  .py-mdg-5 {
    padding-top: 3rem !important; }
  .pr-mdg-5,
  .px-mdg-5 {
    padding-right: 3rem !important; }
  .pb-mdg-5,
  .py-mdg-5 {
    padding-bottom: 3rem !important; }
  .pl-mdg-5,
  .px-mdg-5 {
    padding-left: 3rem !important; }
  .m-mdg-auto {
    margin: auto !important; }
  .mt-mdg-auto,
  .my-mdg-auto {
    margin-top: auto !important; }
  .mr-mdg-auto,
  .mx-mdg-auto {
    margin-right: auto !important; }
  .mb-mdg-auto,
  .my-mdg-auto {
    margin-bottom: auto !important; }
  .ml-mdg-auto,
  .mx-mdg-auto {
    margin-left: auto !important; } }

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xgl-0 {
    margin: 0 !important; }
  .mt-xgl-0,
  .my-xgl-0 {
    margin-top: 0 !important; }
  .mr-xgl-0,
  .mx-xgl-0 {
    margin-right: 0 !important; }
  .mb-xgl-0,
  .my-xgl-0 {
    margin-bottom: 0 !important; }
  .ml-xgl-0,
  .mx-xgl-0 {
    margin-left: 0 !important; }
  .m-xgl-1 {
    margin: 0.25rem !important; }
  .mt-xgl-1,
  .my-xgl-1 {
    margin-top: 0.25rem !important; }
  .mr-xgl-1,
  .mx-xgl-1 {
    margin-right: 0.25rem !important; }
  .mb-xgl-1,
  .my-xgl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xgl-1,
  .mx-xgl-1 {
    margin-left: 0.25rem !important; }
  .m-xgl-2 {
    margin: 0.5rem !important; }
  .mt-xgl-2,
  .my-xgl-2 {
    margin-top: 0.5rem !important; }
  .mr-xgl-2,
  .mx-xgl-2 {
    margin-right: 0.5rem !important; }
  .mb-xgl-2,
  .my-xgl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xgl-2,
  .mx-xgl-2 {
    margin-left: 0.5rem !important; }
  .m-xgl-3 {
    margin: 1rem !important; }
  .mt-xgl-3,
  .my-xgl-3 {
    margin-top: 1rem !important; }
  .mr-xgl-3,
  .mx-xgl-3 {
    margin-right: 1rem !important; }
  .mb-xgl-3,
  .my-xgl-3 {
    margin-bottom: 1rem !important; }
  .ml-xgl-3,
  .mx-xgl-3 {
    margin-left: 1rem !important; }
  .m-xgl-4 {
    margin: 1.5rem !important; }
  .mt-xgl-4,
  .my-xgl-4 {
    margin-top: 1.5rem !important; }
  .mr-xgl-4,
  .mx-xgl-4 {
    margin-right: 1.5rem !important; }
  .mb-xgl-4,
  .my-xgl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xgl-4,
  .mx-xgl-4 {
    margin-left: 1.5rem !important; }
  .m-xgl-5 {
    margin: 3rem !important; }
  .mt-xgl-5,
  .my-xgl-5 {
    margin-top: 3rem !important; }
  .mr-xgl-5,
  .mx-xgl-5 {
    margin-right: 3rem !important; }
  .mb-xgl-5,
  .my-xgl-5 {
    margin-bottom: 3rem !important; }
  .ml-xgl-5,
  .mx-xgl-5 {
    margin-left: 3rem !important; }
  .p-xgl-0 {
    padding: 0 !important; }
  .pt-xgl-0,
  .py-xgl-0 {
    padding-top: 0 !important; }
  .pr-xgl-0,
  .px-xgl-0 {
    padding-right: 0 !important; }
  .pb-xgl-0,
  .py-xgl-0 {
    padding-bottom: 0 !important; }
  .pl-xgl-0,
  .px-xgl-0 {
    padding-left: 0 !important; }
  .p-xgl-1 {
    padding: 0.25rem !important; }
  .pt-xgl-1,
  .py-xgl-1 {
    padding-top: 0.25rem !important; }
  .pr-xgl-1,
  .px-xgl-1 {
    padding-right: 0.25rem !important; }
  .pb-xgl-1,
  .py-xgl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xgl-1,
  .px-xgl-1 {
    padding-left: 0.25rem !important; }
  .p-xgl-2 {
    padding: 0.5rem !important; }
  .pt-xgl-2,
  .py-xgl-2 {
    padding-top: 0.5rem !important; }
  .pr-xgl-2,
  .px-xgl-2 {
    padding-right: 0.5rem !important; }
  .pb-xgl-2,
  .py-xgl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xgl-2,
  .px-xgl-2 {
    padding-left: 0.5rem !important; }
  .p-xgl-3 {
    padding: 1rem !important; }
  .pt-xgl-3,
  .py-xgl-3 {
    padding-top: 1rem !important; }
  .pr-xgl-3,
  .px-xgl-3 {
    padding-right: 1rem !important; }
  .pb-xgl-3,
  .py-xgl-3 {
    padding-bottom: 1rem !important; }
  .pl-xgl-3,
  .px-xgl-3 {
    padding-left: 1rem !important; }
  .p-xgl-4 {
    padding: 1.5rem !important; }
  .pt-xgl-4,
  .py-xgl-4 {
    padding-top: 1.5rem !important; }
  .pr-xgl-4,
  .px-xgl-4 {
    padding-right: 1.5rem !important; }
  .pb-xgl-4,
  .py-xgl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xgl-4,
  .px-xgl-4 {
    padding-left: 1.5rem !important; }
  .p-xgl-5 {
    padding: 3rem !important; }
  .pt-xgl-5,
  .py-xgl-5 {
    padding-top: 3rem !important; }
  .pr-xgl-5,
  .px-xgl-5 {
    padding-right: 3rem !important; }
  .pb-xgl-5,
  .py-xgl-5 {
    padding-bottom: 3rem !important; }
  .pl-xgl-5,
  .px-xgl-5 {
    padding-left: 3rem !important; }
  .m-xgl-auto {
    margin: auto !important; }
  .mt-xgl-auto,
  .my-xgl-auto {
    margin-top: auto !important; }
  .mr-xgl-auto,
  .mx-xgl-auto {
    margin-right: auto !important; }
  .mb-xgl-auto,
  .my-xgl-auto {
    margin-bottom: auto !important; }
  .ml-xgl-auto,
  .mx-xgl-auto {
    margin-left: auto !important; } }

@media (min-width: 1920px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: "Libre Baskerville", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 360px) {
  .text-xsm-left {
    text-align: left !important; }
  .text-xsm-right {
    text-align: right !important; }
  .text-xsm-center {
    text-align: center !important; } }

@media (min-width: 767px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 800px) {
  .text-mdg-left {
    text-align: left !important; }
  .text-mdg-right {
    text-align: right !important; }
  .text-mdg-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xgl-left {
    text-align: left !important; }
  .text-xgl-right {
    text-align: right !important; }
  .text-xgl-center {
    text-align: center !important; } }

@media (min-width: 1920px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #CF9338 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #ab7729 !important; }

.text-secondary {
  color: #9F9F9F !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #868686 !important; }

.text-success {
  color: #697F2A !important; }

a.text-success:hover, a.text-success:focus {
  color: #49591d !important; }

.text-info {
  color: #192c47 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0c1521 !important; }

.text-warning {
  color: #FCBE16 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #dca203 !important; }

.text-danger {
  color: #DF5C60 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #d73136 !important; }

.text-light {
  color: #F5F5F5 !important; }

a.text-light:hover, a.text-light:focus {
  color: gainsboro !important; }

.text-dark {
  color: #221F20 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #070707 !important; }

.text-body {
  color: #221F20 !important; }

.text-muted {
  color: #9F9F9F !important; }

.text-black-50 {
  color: rgba(34, 31, 32, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1024px !important; }
  .container {
    min-width: 1024px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #221F20; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #D3D2D2 !important; } }

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 3, 2018 */
@media (max-width: 359.98px) {
  .container {
    padding-left: 16px;
    padding-right: 16px; }
  .row {
    margin-left: -16px;
    margin-right: -16px; }
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-xsm-1, .col-xsm-2, .col-xsm-3, .col-xsm-4, .col-xsm-5, .col-xsm-6, .col-xsm-7, .col-xsm-8, .col-xsm-9, .col-xsm-10, .col-xsm-11, .col-xsm-12, .col-xsm, .col-xsm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, header [class*="col-"], .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-mdx-1, .col-mdx-2, .col-mdx-3, .col-mdx-4, .col-mdx-5, .col-mdx-6, .col-mdx-7, .col-mdx-8, .col-mdx-9, .col-mdx-10, .col-mdx-11, .col-mdx-12, .col-mdx, .col-mdx-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    padding-left: 16px;
    padding-right: 16px; } }

@media (min-width: 1199.98px) and (max-width: 1920px) {
  .container {
    max-width: calc(1600px + 10%);
    padding-left: 5%;
    padding-right: 5%; } }

html {
  box-sizing: border-box;
  height: 100%;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }
  html {
    font-size: 13px; }
    @media screen and (min-width: 767px) {
      html {
        font-size: calc(13px + 3 * ((100vw - 767px) / 1153)); } }
    @media screen and (min-width: 1920px) {
      html {
        font-size: 16px; } }

body {
  padding-top: 150px; }
  @media (max-width: 767.98px) {
    body {
      padding-top: 60px; } }
  body.is-clipped {
    width: 100%;
    height: 100px;
    overflow: hidden; }

.pos-a-container {
  width: 100%;
  padding-right: 24px;
  padding-left: 24px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1688px;
  position: absolute;
  left: 0;
  right: 0; }
  @media (min-width: 1200px) {
    .pos-a-container {
      max-width: calc(1600px + 10%);
      padding-left: 5% !important;
      padding-right: 5% !important; } }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5 {
  color: #CF9338; }
  h1.black, .h1.black, h2.black, .h2.black, h3.black, .h3.black, h4.black, .h4.black, h5.black, .h5.black {
    color: #221F20 !important; }

h1, .h1 {
  font-size: 4.6875rem; }
  @media (max-width: 767.98px) {
    h1, .h1 {
      font-size: 2.1875rem; } }

h2, .h2 {
  font-size: 3.125rem; }
  @media (max-width: 767.98px) {
    h2, .h2 {
      font-size: 1.8125rem; } }

h3, .h3 {
  font-size: 2.5rem; }
  @media (max-width: 767.98px) {
    h3, .h3 {
      font-size: 1.5625rem; } }

h4, .h4 {
  font-size: 2.1875rem; }
  @media (max-width: 767.98px) {
    h4, .h4 {
      font-size: 1.25rem; } }

h5, .h5 {
  font-size: 1.5625rem; }
  @media (max-width: 767.98px) {
    h5, .h5 {
      font-size: 1.125rem; } }

.mb-35 {
  margin-bottom: 2.1875rem !important; }

@media (min-width: 1024px) {
  .mb-up-lg-80 {
    margin-bottom: 5rem !important; } }

.max-1640 {
  max-width: 1640px !important; }

.max-535 {
  max-width: 535px; }

.max-560 {
  max-width: 560px; }

.max-630 {
  max-width: 630px; }

.max-500 {
  max-width: 500px; }

.max-740 {
  max-width: 740px; }

.max-720 {
  max-width: 720px !important; }

.pb-80 {
  padding-bottom: 5rem; }

.text-lineHeight {
  position: relative; }
  .text-lineHeight span {
    cursor: pointer;
    color: #6D6E71; }
  .text-lineHeight.is-hidden {
    overflow: hidden;
    padding-right: 1.375rem; }
    .text-lineHeight.is-hidden span {
      position: absolute;
      right: 0;
      bottom: 0; }
  .text-lineHeight:not(.is-hidden) {
    height: auto !important; }

.cover,
.bg-contain {
  background-repeat: no-repeat;
  background-position: center; }

.cover {
  background-size: cover; }

.bg-contain {
  background-size: contain; }

.opacity-zero {
  opacity: 0 !important; }

header {
  height: 150px;
  display: flex;
  background-color: #fff;
  transition: 0.3s all;
  align-items: center; }
  @media (min-width: 768px) {
    header.is-sticky {
      height: 90px; }
      header.is-sticky .navbar-toggler {
        width: 30px; }
      header.is-sticky .navbar-toggler-icon {
        width: 30px; }
        header.is-sticky .navbar-toggler-icon:before {
          top: -10px; }
        header.is-sticky .navbar-toggler-icon:after {
          top: 10px; }
      header.is-sticky .header-logo {
        transform: scale(0.6); }
      header.is-sticky .nav-header a {
        font-size: 1rem; }
      header.is-sticky .btn-search {
        width: 22px;
        height: 22px; } }
  @media (max-width: 767.98px) {
    header {
      height: 60px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1020; } }
  header .row {
    align-items: center; }
    @media (max-width: 767.98px) {
      header .row {
        margin-left: 0;
        margin-right: 0;
        position: relative;
        justify-content: center;
        padding-left: 30px; }
        header .row .col-navbar-toggle {
          position: absolute;
          left: 0; } }
  @media (max-width: 767.98px) {
    header [class*="col-"] {
      padding-left: 0;
      padding-right: 0; } }

.header-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  text-align: center;
  max-width: 292px; }
  @media (min-width: 767px) {
    .header-logo {
      transform: scale(1);
      transition: 0.3s all; } }

.nav-header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;
  margin-right: -24px; }
  @media (max-width: 799.98px) {
    .nav-header {
      margin-right: -5px; } }
  .nav-header li {
    padding: 0.625rem 1.875rem; }
    @media (max-width: 799.98px) {
      .nav-header li {
        padding: 0.625rem 0.3125rem; } }
  .nav-header a {
    display: inline-block;
    font-family: "Libre Baskerville", serif;
    font-size: 1.25rem;
    transition: 0.3s all; }

.btn-search {
  width: 1.875rem;
  height: 1.875rem;
  background: url(../../../medias/images/Search.svg) no-repeat;
  background-size: cover;
  transition: 0.3s all; }

footer {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }
  footer.container {
    max-width: 1508px; }
    @media (min-width: 1199.98px) and (max-width: 1920px) {
      footer.container {
        max-width: calc(1460px + 10%);
        padding-left: 5%;
        padding-right: 5%; } }
  @media (max-width: 767.98px) {
    footer {
      padding-top: 1.5625rem;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16); } }

.footer__top--logo {
  text-align: center; }
  @media (max-width: 767.98px) {
    .footer__top--logo img {
      max-width: 85%; } }
  .footer__top--logo .p {
    font-size: 1.25rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    color: #221F20; }
    @media (max-width: 1919.98px) {
      .footer__top--logo .p {
        margin-bottom: 0; } }
    @media (max-width: 767.98px) {
      .footer__top--logo .p {
        font-size: 1rem;
        max-width: 10.625rem;
        margin: 0.9375rem auto 0; }
        .footer__top--logo .p span {
          display: none; } }

.footer__middle {
  border-top: 1px solid rgba(109, 110, 113, 0.4);
  border-bottom: 1px solid rgba(109, 110, 113, 0.4); }
  .footer__middle .h3 {
    margin-bottom: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: center; }
    @media (max-width: 767.98px) {
      .footer__middle .h3 {
        padding-top: 0.875rem;
        padding-bottom: 0.875rem; } }
    .footer__middle .h3 a {
      text-align: center;
      color: #CF9338;
      font-size: 1.875rem; }
      @media (max-width: 767.98px) {
        .footer__middle .h3 a {
          font-size: 1.25rem;
          line-height: 1.2; } }
      @media (min-width: 1200px) {
        .footer__middle .h3 a:after {
          content: '';
          background-image: url(../../../medias/images/icon-bee.svg);
          width: 8.625rem;
          height: 2.4375rem;
          display: inline-block;
          background-size: cover;
          margin-left: 0.4375rem;
          transition: 0.3s background-image; } }
      .footer__middle .h3 a:hover {
        color: #221F20; }
        .footer__middle .h3 a:hover:after {
          background-image: url(../../../medias/images/icon-bee-black.svg); }

.footer__bottom {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem; }
  @media (max-width: 1919.98px) {
    .footer__bottom {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem; } }

@media (min-width: 1200px) {
  .nav-copyright {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; } }

.nav-copyright ul {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (max-width: 767.98px) {
    .nav-copyright ul {
      flex-direction: column; } }

@media (min-width: 767px) {
  .nav-copyright li:not(:last-child):after {
    content: '-';
    margin-left: 0.625rem;
    margin-right: 0.625rem; } }

.nav-copyright li, .nav-copyright span, .nav-copyright a {
  color: #6D6E71; }
  @media (max-width: 767.98px) {
    .nav-copyright li, .nav-copyright span, .nav-copyright a {
      font-size: 0.75rem;
      line-height: 1.41; } }

.nav-copyright a:hover {
  color: #CF9338; }

@media (min-width: 1200px) {
  .nav-footer {
    margin-top: 1.875rem; } }

.nav-footer ul {
  margin-bottom: 0;
  padding: 0.9375rem 0;
  list-style: none;
  display: flex;
  flex-wrap: nowrap;
  margin-left: -0.625rem;
  margin-right: -0.625rem;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 1199.98px) {
    .nav-footer ul {
      flex-direction: column; } }

.nav-footer li {
  text-align: center; }

.nav-footer a {
  font-size: 1.5625rem;
  font-family: "Libre Baskerville", serif; }
  @media (max-width: 799.98px) {
    .nav-footer a {
      flex-direction: column;
      font-size: 1.25rem;
      margin-top: 0.25rem;
      margin-bottom: 0.25rem; } }

.nav--social__links {
  justify-content: flex-end; }
  @media (max-width: 1919.98px) {
    .nav--social__links {
      justify-content: center; } }
  .nav--social__links ul {
    display: flex;
    align-items: center; }
    @media (min-width: 1200px) {
      .nav--social__links ul {
        margin-right: -1.375rem; } }
    .nav--social__links ul a {
      width: 1.875rem;
      height: 1.875rem;
      margin: 0.6875rem 1.375rem;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: none; }
    .nav--social__links ul [class*="link"] {
      background-repeat: no-repeat;
      background-position: center; }

.link-facebook {
  background-image: url(../../../medias/images/Facebook.svg);
  background-size: 1rem 1.875rem; }
  .link-facebook:hover {
    background-image: url(../../../medias/images/Facebook-black.svg); }

.link-instagram {
  background-image: url(../../../medias/images/Instagram.svg);
  background-size: 1.875rem 1.875rem; }
  .link-instagram:hover {
    background-image: url(../../../medias/images/Instagram-black.svg); }

.link-google {
  background-image: url(../../../medias/images/google.svg);
  background-size: 1.625rem 1.625rem; }
  .link-google:hover {
    background-image: url(../../../medias/images/google-black.svg); }

.c-nav__close {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.83);
  display: none;
  z-index: 1035;
  cursor: pointer; }
  .c-nav__close.is-active {
    display: block; }

@media (max-width: 767.98px) {
  .navbarHeader--logo {
    position: relative;
    padding-left: 30px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center; }
    .navbarHeader--logo .navbar-toggler {
      position: absolute;
      left: 0; } }

#navbarHeader {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 1040;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  max-width: 430px;
  width: 100%;
  transform: translateX(-110%);
  transition: 0.2s all;
  overflow-y: scroll;
  padding: 3.4375rem 3.125rem; }
  @media (max-width: 767.98px) {
    #navbarHeader {
      padding: 0.625rem 2.1875rem 3.125rem;
      max-width: 360px; } }
  @media (max-width: 359.98px) {
    #navbarHeader {
      padding-left: 3%;
      padding-right: 3%; } }
  #navbarHeader .navbar-toggler-icon {
    background-color: transparent; }
    #navbarHeader .navbar-toggler-icon:before {
      transform: rotate(45deg);
      top: 0; }
    #navbarHeader .navbar-toggler-icon:after {
      transform: rotate(-45deg);
      top: 0; }
  #navbarHeader.is-opened {
    transform: translateX(0); }
  #navbarHeader .nav-item {
    margin-top: 1rem;
    margin-bottom: 1rem; }
    #navbarHeader .nav-item:last-child {
      background-color: #CF9338;
      color: #fff;
      margin-left: -3.125rem;
      margin-right: -3.125rem; }
      @media (max-width: 767.98px) {
        #navbarHeader .nav-item:last-child {
          margin-left: -2.1875rem;
          margin-right: -2.1875rem; } }
      #navbarHeader .nav-item:last-child .nav-link {
        text-align: center;
        color: #fff;
        padding-left: 3.125rem;
        padding-right: 3.125rem; }
        @media (max-width: 767.98px) {
          #navbarHeader .nav-item:last-child .nav-link {
            padding-left: 2.1875rem;
            padding-right: 2.1875rem; } }
  #navbarHeader .nav-link {
    font-family: "Libre Baskerville", serif;
    font-size: 1.5625rem;
    line-height: 1.24;
    position: relative; }
    @media (max-width: 767.98px) {
      #navbarHeader .nav-link {
        font-size: 1.5rem;
        line-height: 1.2;
        padding-top: 0.625rem;
        padding-bottom: 0.625rem; } }
    #navbarHeader .nav-link.has-child:before {
      content: '';
      position: absolute;
      display: block;
      height: 2px;
      width: 14px;
      background-color: #221F20;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 767.98px) {
        #navbarHeader .nav-link.has-child:before {
          width: 12px; } }
    #navbarHeader .nav-link.has-child:after {
      content: '';
      position: absolute;
      display: block;
      height: 14px;
      width: 2px;
      background-color: #221F20;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      opacity: 1;
      transition: 0.2s opacity; }
      @media (max-width: 767.98px) {
        #navbarHeader .nav-link.has-child:after {
          height: 12px;
          right: 5px; } }
    #navbarHeader .nav-link.has-child.is-active {
      color: #CF9338; }
      #navbarHeader .nav-link.has-child.is-active:before {
        background-color: #CF9338; }
      #navbarHeader .nav-link.has-child.is-active:after {
        opacity: 0; }
    #navbarHeader .nav-link.active {
      color: #CF9338; }
    #navbarHeader .nav-link.is-active {
      color: #CF9338; }
  #navbarHeader .navbar-toggler {
    margin-bottom: 2.5rem; }

.nav-lavel2 {
  display: none; }
  .nav-lavel2 li {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
  .nav-lavel2 a {
    display: block;
    position: relative;
    padding-left: 1.875rem;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
    font-size: 1.125rem;
    font-family: "Libre Baskerville", serif; }
    @media (max-width: 1199.98px) {
      .nav-lavel2 a {
        font-size: 1rem;
        padding-left: 1.25rem; } }
    .nav-lavel2 a:before {
      content: '';
      display: block;
      position: absolute;
      width: 0.9375rem;
      height: 0.9375rem;
      background-color: #D3D2D2;
      border-radius: 50%;
      left: 0;
      transition: 0.3s background-color;
      top: 50%;
      transform: translateY(-50%); }
      @media (max-width: 1199.98px) {
        .nav-lavel2 a:before {
          width: 0.625rem;
          height: 0.625rem; } }
    .nav-lavel2 a:hover, .nav-lavel2 a.active {
      color: #CF9338; }
      .nav-lavel2 a:hover:before, .nav-lavel2 a.active:before {
        background-color: #CF9338; }

@media (max-width: 767.98px) {
  .navbar-toggler {
    width: 24px; } }

.navbar-toggler-icon {
  transition: 0.3s all; }
  @media (max-width: 767.98px) {
    .navbar-toggler-icon {
      width: 24px; }
      .navbar-toggler-icon:before {
        top: -10px; }
      .navbar-toggler-icon:after {
        top: 10px; } }

.container-contact {
  max-width: calc(1100px + 10%);
  padding-bottom: 4rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem; }
  @media (max-width: 767.98px) {
    .container-contact {
      padding-bottom: 2.3125rem; } }
  @media (max-width: 767.98px) {
    .container-contact {
      padding-bottom: 2.3125rem; }
      .container-contact .nav-pills {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1.25rem;
        margin-right: -1.25rem; }
        .container-contact .nav-pills .nav-item {
          max-width: 285px;
          margin-left: 1.25rem;
          margin-right: 1.25rem; } }
  .container-contact .form-control:not(textarea) {
    height: 3.125rem; }
  .container-contact .p {
    margin-bottom: 1.875rem; }
    @media (min-width: 767px) {
      .container-contact .p {
        text-align: center; } }
  .container-contact .row {
    margin-left: -0.9375rem;
    margin-right: -0.9375rem; }
  .container-contact [class*="col-"] {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem; }
  .container-contact .btn {
    width: 13.75rem;
    background-color: #CF9338;
    color: #fff;
    border: 0 none; }
    .container-contact .btn:before {
      background-image: url(../../../medias/images/bee-btn-icon-white.svg); }
    .container-contact .btn:hover {
      background-color: #221F20; }
    @media (max-width: 767.98px) {
      .container-contact .btn {
        margin-top: 1.375rem; } }
  .container-contact .d-flex {
    align-items: center; }
  @media (max-width: 767.98px) {
    .container-contact .nav-link {
      font-family: "Open Sans", sans-serif; } }

.custom-control-label {
  font-size: 0.75rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url(../../../medias/images/case-cocher.svg); }

.custom-card {
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  padding: 2.1875rem 3.125rem; }
  @media (max-width: 767.98px) {
    .custom-card {
      padding: 1.25rem 2.3125rem;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.16);
      max-width: 100%; } }
  @media (max-width: 359.98px) {
    .custom-card {
      padding-left: 3%;
      padding-right: 3%; } }

.produit-card {
  padding: 1.25rem 2.3125rem;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16); }
  @media (min-width: 767px) {
    .produit-card {
      padding: 2.5rem 2rem; } }
  @media (min-width: 767px) {
    .produit-card h3, .produit-card .h3 {
      line-height: 1.125;
      margin-bottom: 1.875rem; } }
  @media (min-width: 767px) {
    .produit-card .btn {
      margin-top: 1rem;
      float: right; } }
  @media (max-width: 767.98px) {
    .produit-card .btn {
      display: block;
      max-width: 16.25rem;
      margin-left: auto;
      margin-right: auto;
      margin-top: 1.25rem; } }
  @media (max-width: 359.98px) {
    .produit-card {
      padding-left: 3%;
      padding-right: 3%; } }

.container-chiffres {
  padding-bottom: 3.125rem; }
  .container-chiffres [class*="col-"] {
    display: flex; }
  .container-chiffres .card-chiffre {
    width: 100%;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 767.98px) {
      .container-chiffres .card-chiffre {
        max-width: 250px; } }

.card-chiffre {
  min-height: 21.875rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }
  @media (max-width: 767.98px) {
    .card-chiffre {
      min-height: 250px;
      margin-top: 1.25rem;
      margin-bottom: 1.25rem;
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16); } }
  .card-chiffre .h1 {
    line-height: 1.36;
    margin-bottom: 0; }
    @media (max-width: 767.98px) {
      .card-chiffre .h1 {
        font-size: 2.8125rem; } }
  .card-chiffre .h3 {
    line-height: 1.125;
    margin-bottom: 0; }
    @media (max-width: 767.98px) {
      .card-chiffre .h3 {
        font-size: 2.8125rem; } }
  .card-chiffre .p {
    margin-top: 0.75rem;
    font-size: 1.375rem;
    font-family: "Libre Baskerville", serif;
    line-height: 1.31;
    margin-bottom: 0;
    color: #221F20;
    font-weight: 700;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto; }
    @media (max-width: 767.98px) {
      .card-chiffre .p {
        max-width: none; } }

@media (min-width: 1200px) {
  .bandeau__card {
    margin-left: auto;
    margin-right: 5.625rem; } }

.container-bandeau .pos-a-container {
  bottom: 1.5625rem; }
  @media (max-width: 1199.98px) {
    .container-bandeau .pos-a-container {
      position: static;
      padding-left: 0;
      padding-right: 0; }
      .container-bandeau .pos-a-container .bandeau__card {
        max-width: 100%; } }

@media (min-width: 1200px) {
  .container-bandeau .pos-a-container {
    padding-left: 3rem;
    padding-right: 3rem; } }

.container-bandeau .card-actualite {
  margin-left: auto; }

.card-actualite::after {
  display: block;
  clear: both;
  content: ""; }

@media (min-width: 767px) {
  .card-actualite {
    padding: 2.5rem 2.1875rem; }
    .card-actualite .btn {
      float: right;
      margin-top: 0.375rem; } }

.bandeau-actualite {
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4.6875rem; }
  .bandeau-actualite .cover {
    background-position: left; }
  @media (min-width: 767px) {
    .bandeau-actualite .bandeau__body {
      padding-top: 7.1875rem;
      padding-bottom: 7.1875rem; }
    .bandeau-actualite .card-actualite {
      margin-right: 5%; } }
  @media (max-width: 767.98px) {
    .bandeau-actualite {
      margin-bottom: 2.5rem; }
      .bandeau-actualite .bandeau__body {
        background-image: none !important; }
        .bandeau-actualite .bandeau__body img {
          min-height: 215px; }
      .bandeau-actualite .card-actualite {
        box-shadow: none; }
        .bandeau-actualite .card-actualite .btn {
          display: block;
          max-width: 13.75rem;
          margin-left: auto;
          margin-right: auto;
          margin-top: 1.25rem; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }
  .slick-slide, .slick-slide:focus {
    outline: none; }

.slick-arrow.slick-hidden {
  display: none; }

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 30%;
  display: block;
  width: 2.375rem;
  height: 1.625rem;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent; }
  @media (max-width: 767.98px) {
    .slick-prev,
    .slick-next {
      width: 1.625rem;
      height: 1.125rem; } }

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25; }

.slick-prev:before,
.slick-next:before {
  content: '';
  background-repeat: no-repeat;
  width: 2.375rem;
  height: 1.625rem;
  background-size: cover;
  display: block; }
  @media (max-width: 767.98px) {
    .slick-prev:before,
    .slick-next:before {
      width: 1.625rem;
      height: 1.125rem; } }

.slick-prev {
  left: 0; }

[dir='rtl'] .slick-prev {
  right: 0;
  left: auto; }

.slick-prev:before {
  background-image: url(../../../medias/images/right-arrow.svg); }
  @media (max-width: 767.98px) {
    .slick-prev:before {
      background-image: url(../../../medias/images/right-arrow-black.svg); } }

[dir='rtl'] .slick-prev:before {
  content: ''; }

.slick-next {
  right: 0; }

[dir='rtl'] .slick-next {
  right: auto;
  left: 0; }

.slick-next:before {
  background-image: url(../../../medias/images/left-arrow.svg); }
  @media (max-width: 767.98px) {
    .slick-next:before {
      background-image: url(../../../medias/images/left-arrow-black.svg); } }

[dir='rtl'] .slick-next:before {
  content: '←'; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 1.875rem; }

.slick-dots {
  position: absolute;
  bottom: 1.5625rem;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center; }

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 1.375rem;
  height: 1.375rem;
  margin: 0 0.375rem;
  padding: 0;
  cursor: pointer; }
  @media (max-width: 767.98px) {
    .slick-dots li {
      width: 1.0625rem;
      height: 1.0625rem;
      margin: 0 0.3125rem; } }

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 1.375rem;
  height: 1.375rem;
  padding: 0.375rem;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: transparent; }
  @media (max-width: 767.98px) {
    .slick-dots li button {
      width: 1.0625rem;
      height: 1.0625rem; } }

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none; }

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1; }

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.375rem;
  height: 1.375rem;
  content: '';
  background-color: #fff;
  border-radius: 50%; }
  @media (max-width: 767.98px) {
    .slick-dots li button:before {
      width: 1.0625rem;
      height: 1.0625rem; } }

.slick-dots li.slick-active button:before {
  background-color: #CF9338; }

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle; }
  .select2-container.select2-container--open .select2-selection--single .select2-selection__rendered:after {
    transform: rotate(180deg); }
  .select2-container:focus {
    outline: 0 none; }
  .select2-container .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 3.125rem;
    user-select: none;
    -webkit-user-select: none;
    position: relative; }
    .select2-container .select2-selection--single:focus {
      outline: 0 none; }
    .select2-container .select2-selection--single .select2-selection__rendered {
      position: relative;
      z-index: 1025;
      display: block;
      padding: 0.6875rem 1rem 0.75rem 0.875rem;
      border: 1px solid #707070;
      font-size: 0.875rem;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      outline: 0 none;
      border-radius: 1.5625rem;
      text-align: center;
      font-size: 1.25rem; }
      .select2-container .select2-selection--single .select2-selection__rendered:after {
        content: '';
        width: 0.875rem;
        height: 0.625rem;
        margin-left: 1.25rem;
        background: url(../../../medias/images/arrow-down.svg) no-repeat;
        background-size: cover;
        display: inline-block;
        vertical-align: middle;
        transition: 0.3s all; }
    .select2-container .select2-selection--single .select2-selection__clear {
      position: relative; }
  .select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
    padding-right: 0.5rem;
    padding-left: 1.25rem; }
  .select2-container .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 2rem;
    user-select: none;
    -webkit-user-select: none; }
    .select2-container .select2-selection--multiple .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 0.5rem;
      text-overflow: ellipsis;
      white-space: nowrap; }
  .select2-container .select2-search--inline {
    float: left; }
    .select2-container .select2-search--inline .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 0.3125rem;
      padding: 0; }
      .select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
        -webkit-appearance: none; }

.select2-dropdown {
  background-color: #fff;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1020;
  border: 1px solid #D3D2D2;
  border-radius: 1.5625rem; }

.select2-results {
  display: block; }

.select2-results__options {
  list-style: none;
  margin: 0; }

.select2-results__option {
  color: #221F20;
  padding: 0.625rem 1.25rem;
  transition: 0.3s all;
  text-align: center;
  font-size: 1.25rem;
  user-select: none;
  -webkit-user-select: none; }
  .select2-results__option:first-child {
    display: none; }
  .select2-results__option[aria-selected] {
    cursor: pointer; }

.select2-container--open .select2-dropdown {
  left: 0; }

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  margin-top: 25px; }

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 2.5rem;
  padding-bottom: 1rem;
  position: relative;
  margin-top: -25px; }
  .select2-container--open .select2-dropdown--below:before {
    position: absolute;
    content: '';
    width: 15px;
    height: 12px;
    background-size: cover;
    display: block;
    top: 15px;
    right: 15px;
    transform: rotate(180deg); }

.select2-search--dropdown {
  display: block;
  padding: 4px; }
  .select2-search--dropdown .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box; }
    .select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
      -webkit-appearance: none; }
  .select2-search--dropdown.select2-search--hide {
    display: none; }

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0); }

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important; }

@media (min-width: 767px) {
  .container-slider-home {
    margin-top: 2.125rem; } }

@media (max-width: 359.98px) {
  .container-slider-home {
    padding-left: 0;
    padding-right: 0; }
    .container-slider-home .row-slider-home {
      margin-left: 0;
      margin-right: 0; } }

.sliderDots-byOne.slick-dotted.slick-slider {
  margin-bottom: 0; }

@media (min-width: 767px) {
  .sliderDots-byOne .slick-dots {
    text-align: left;
    left: 1.5625rem; } }

@media (max-width: 767.98px) {
  .sliderDots-byOne .slick-dots {
    bottom: 0.5625rem; } }

@media (max-width: 767.98px) {
  .sliderDots-byOne .slick-dots li button:before {
    margin: 0 0.3125rem; } }

@media (max-width: 767.98px) {
  .sliderDots-byOne .slick-dots li button:before {
    width: 1.0625rem;
    height: 1.0625rem; } }

.sliderDots-byOne--item {
  position: relative;
  display: block !important; }
  .sliderDots-byOne--item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .sliderDots-byOne--item .p {
    font-size: 3.4375rem;
    font-family: "Libre Baskerville", serif;
    line-height: 1.27;
    color: black;
    background-color: rgba(255, 255, 255, 0.62);
    max-width: 30.3125rem;
    padding: 0.625rem 1.25rem;
    position: absolute;
    bottom: 1.5625rem;
    right: 1.5625rem;
    text-align: center;
    margin-bottom: 0; }
    @media (min-width: 767px) and (max-width: 1919.98px) {
      .sliderDots-byOne--item .p {
        font-size: 2.66667vw; } }
    @media (max-width: 767.98px) {
      .sliderDots-byOne--item .p {
        font-size: 1.125rem;
        bottom: 2.25rem;
        padding: 0.3125rem 0.625rem;
        left: 1.5625rem; } }

.row-slider-home {
  height: 100%; }
  .row-slider-home .fadeUp {
    height: 100%; }
  .row-slider-home .col-left {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%; }
    @media (min-width: 1200px) {
      .row-slider-home .col-left {
        width: 70%;
        max-width: 70%;
        flex: 0 0 70%; } }
  .row-slider-home .col-right {
    max-width: 100%;
    width: 100%;
    flex: 0 0 100%; }
    @media (min-width: 1200px) {
      .row-slider-home .col-right {
        width: 30%;
        max-width: 30%;
        flex: 0 0 30%; } }
    @media (min-width: 800px) and (max-width: 1199.98px) {
      .row-slider-home .col-right {
        display: flex; }
        .row-slider-home .col-right .b-produit {
          margin-bottom: 0; }
          .row-slider-home .col-right .b-produit:not(:last-child) {
            margin-right: 10px; } }
    @media (min-width: 768px) and (max-width: 1023.98px) {
      .row-slider-home .col-right .b-produit {
        flex-wrap: wrap; } }
  .row-slider-home .sliderDots-byOne {
    height: 100%; }
    .row-slider-home .sliderDots-byOne .slick-list, .row-slider-home .sliderDots-byOne .slick-slide > div, .row-slider-home .sliderDots-byOne .slick-track, .row-slider-home .sliderDots-byOne .sliderDots-byOne--item {
      height: 100%; }

@media (min-width: 767px) {
  .row-slider-home {
    margin-left: -10px;
    margin-right: -10px; }
    .row-slider-home .col-left,
    .row-slider-home .col-right {
      padding-left: 10px;
      padding-right: 10px; } }

@media (max-width: 1919.98px) {
  #form-newsletter {
    margin-bottom: 1.25rem; } }

@media (min-width: 1200px) {
  #form-newsletter {
    display: flex;
    justify-content: flex-start;
    align-items: center; } }

#form-newsletter .form-group {
  position: relative;
  margin-bottom: 0;
  max-width: 26.875rem;
  width: 100%; }
  @media (max-width: 1919.98px) {
    #form-newsletter .form-group {
      margin-left: auto;
      margin-right: auto; } }
  #form-newsletter .form-group.valid [class*="btn"] {
    right: 1.875rem; }

#form-newsletter label:not(.error) {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  display: block;
  text-align: center; }
  @media (min-width: 767px) {
    #form-newsletter label:not(.error) {
      margin-right: 0;
      font-size: 1.25rem; } }
  @media (min-width: 1200px) {
    #form-newsletter label:not(.error) {
      margin-right: 2.5rem; } }

#form-newsletter .form-control,
#form-newsletter [class*="btn"] {
  height: 3.5rem; }
  @media (max-width: 767.98px) {
    #form-newsletter .form-control,
    #form-newsletter [class*="btn"] {
      height: 2.75rem; } }

#form-newsletter .form-control {
  padding-right: 8.125rem;
  margin-bottom: 0;
  font-size: 1.1875rem;
  max-width: 25rem;
  width: 100%; }
  @media (max-width: 799.98px) {
    #form-newsletter .form-control {
      font-size: 0.875rem;
      padding-left: 1.25rem;
      padding-right: 0.625rem; } }
  @media (max-width: 359.98px) {
    #form-newsletter .form-control {
      padding-left: 0.625rem; } }

#form-newsletter [class*="btn"] {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  color: #fff;
  font-size: 1.375rem;
  cursor: pointer;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.8125rem 1rem;
  background-color: #CF9338;
  transition: 0.3s all; }
  @media screen and (prefers-reduced-motion: reduce) {
    #form-newsletter [class*="btn"] {
      transition: none; } }
  @media (max-width: 799.98px) {
    #form-newsletter [class*="btn"] {
      font-size: 1.0625rem;
      padding: 0.625rem 0.8125rem; } }
  #form-newsletter [class*="btn"]:hover {
    background-color: #221F20;
    color: #fff; }

.link {
  font-size: 1.0625rem; }
  .link:before {
    content: '';
    display: inline-block;
    background: url(../../../medias/images/bee-btn-icon.svg) no-repeat;
    width: 1.625rem;
    height: 1.4375rem;
    background-size: cover;
    margin-right: 0.5rem;
    vertical-align: middle; }
  .link:hover::before {
    background-image: url(../../../medias/images/bee-btn-icon-orange.svg); }

.b-produit {
  display: flex;
  min-height: 15.625rem;
  padding: 1.5625rem 0.9375rem;
  position: relative;
  justify-content: center;
  background: #F5F5F5;
  background: linear-gradient(to bottom, #fff 0%, #F5F5F5 100%);
  background: -moz-linear-gradient(top, #fff 0%, #F5F5F5 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #F5F5F5));
  background: -webkit-linear-gradient(top, #fff 0%, #F5F5F5 100%);
  /* Chrome10+,Safari5.1+ */
  background: -webkit-linear-gradient(top, #fff 0%, #F5F5F5 100%);
  background: -o-linear-gradient(top, #fff 0%, #F5F5F5 100%);
  background: -ms-linear-gradient(top, #fff 0%, #F5F5F5 100%);
  /* IE10+ */
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0, startColorstr=#fff, endColorstr=#F5F5F5); }
  @media (min-width: 1200px) {
    .b-produit {
      height: 33%; } }
  .b-produit:not(:last-child) {
    margin-bottom: 0.3125rem; }
  @media (max-width: 767.98px) {
    .b-produit {
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: 0.625rem;
      min-height: 11.25rem; } }
  @media (max-width: 359.98px) {
    .b-produit {
      padding-left: 3%;
      padding-right: 3%; } }
  .b-produit__thumb {
    width: 100%;
    max-width: 8.75rem;
    margin-right: 0.625rem;
    min-height: 13.125rem; }
    @media (max-width: 767.98px) {
      .b-produit__thumb {
        max-width: 6.875rem;
        display: flex;
        align-items: center; } }
  .b-produit__body {
    position: relative;
    padding-bottom: 1.4375rem;
    max-width: 15.625rem;
    align-self: center;
    min-height: 6.875rem; }
    @media (min-width: 1200px) {
      .b-produit__body {
        width: 100%;
        max-width: 100%; } }
    .b-produit__body .link-decouvrir {
      float: right;
      position: absolute;
      bottom: 0;
      right: 0; }
  .b-produit .h3 {
    color: #CF9338;
    line-height: 1.125;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem; }
    @media (max-width: 767.98px) {
      .b-produit .h3 {
        font-size: 1.875rem; } }
  .b-produit .h5 {
    color: #CF9338; }
    @media (max-width: 767.98px) {
      .b-produit .h5 {
        font-size: 1.3125rem; } }

.s-search {
  background-color: #fff;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  display: table;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-top: 130px;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s ease-in-out; }
  @media (max-width: 767.98px) {
    .s-search {
      padding-top: 0.625rem; }
      .s-search .navbarHeader--logo {
        margin-bottom: 1.75rem; } }
  .s-search.is-opened {
    visibility: visible;
    opacity: 1; }
  .s-search .container {
    max-width: 85.625rem; }
    @media (max-width: 767.98px) {
      .s-search .container {
        padding-left: 35px;
        padding-right: 35px; } }
    @media (max-width: 359.98px) {
      .s-search .container {
        padding-left: 3%;
        padding-right: 3%; } }

[id*="form-search"] .form-group {
  display: flex;
  align-items: center; }
  @media (max-width: 1919.98px) {
    [id*="form-search"] .form-group {
      margin-top: 1.875rem;
      margin-bottom: 0.9375rem; } }
  [id*="form-search"] .form-group label.error {
    left: 0; }

[id*="form-search"] .form-control {
  padding-top: 0.8125rem;
  padding-bottom: 0.8125rem;
  padding-left: 5.875rem;
  padding-right: 1rem;
  font-size: 1.125rem;
  background: url(../../../medias/images/Search-grey.svg) no-repeat center left 2.25rem;
  color: #221F20; }
  @media (max-width: 1919.98px) {
    [id*="form-search"] .form-control {
      text-align: center;
      padding: 0.5rem 0.625rem 0.5rem 2.5rem;
      height: 2.5rem;
      font-size: 0.9375rem;
      background: url(../../../medias/images/Search-grey.svg) no-repeat center left 0.6875rem;
      background-size: 1.0625rem; }
      [id*="form-search"] .form-control:focus {
        text-align: left; } }
  [id*="form-search"] .form-control::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background: url(../../../medias/images/remove-search.svg) no-repeat;
    background-size: cover;
    height: 1.5625rem;
    width: 1.5625rem; }
    @media (max-width: 1919.98px) {
      [id*="form-search"] .form-control::-webkit-search-cancel-button {
        height: 1.25rem;
        width: 1.25rem; } }

[id*="form-search"] .reset-search {
  color: #CF9338;
  margin-left: 1.0625rem;
  display: none; }
  [id*="form-search"] .reset-search.is-show {
    display: block; }
  @media (max-width: 1919.98px) {
    [id*="form-search"] .reset-search {
      margin-left: 0.5625rem;
      font-size: 0.9375rem; } }

.close-search {
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  background: url(../../../medias/images/icon-close.svg) no-repeat;
  background-position: center;
  background-size: 1.9375rem 1.9375rem;
  top: 1.75rem;
  right: 2.375rem; }
  @media (max-width: 1919.98px) {
    .close-search {
      width: 1.5rem;
      height: 2.5rem;
      background-size: 1.125rem 1.125rem;
      top: 2px;
      right: auto;
      left: 0; } }

.back-link {
  color: #6D6E71;
  vertical-align: middle; }
  .back-link:before {
    content: '';
    display: inline-block;
    width: 0.625rem;
    height: 0.875rem;
    background: url(../../../medias/images/back-link.svg) no-repeat;
    background-size: 0.625rem 0.875rem;
    vertical-align: middle;
    margin-right: 1rem;
    transform: translateX(0);
    transition: 0.3s transform; }
    @media (min-width: 767px) and (max-width: 1919.98px) {
      .back-link:before {
        width: 0.625rem;
        height: 0.875rem;
        background-size: 0.625rem 0.875rem;
        margin-right: 1rem; } }
  .back-link:hover:before {
    background-image: url(../../../medias/images/back-link-orange.svg);
    transform: translateX(-5px); }
  @media (max-width: 767.98px) {
    .back-link {
      display: none; } }

.nav_header {
  padding-top: 3.75rem;
  padding-bottom: 3.125rem; }
  @media (max-width: 767.98px) {
    .nav_header {
      padding-top: 1.25rem;
      padding-bottom: 1.25rem; } }
  @media (min-width: 1200px) {
    .nav_header--top {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      position: relative; }
      .nav_header--top.padding-x-320 {
        padding-left: 20rem;
        padding-right: 20rem; }
      .nav_header--top .back-link {
        left: 0; }
      .nav_header--top .btn {
        right: 0; }
      .nav_header--top .btn,
      .nav_header--top .back-link {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
  .nav_header h1, .nav_header .h1 {
    color: #CF9338;
    text-align: center;
    margin-bottom: 0; }
  .nav_header .btn {
    background-color: #CF9338;
    color: #fff; }
    .nav_header .btn:hover {
      background-color: #221F20;
      border-color: #221F20; }
    .nav_header .btn:before {
      background-image: url(../../../medias/images/bee-btn-icon-white.svg); }
  .nav_header--links {
    margin-top: 1.875rem; }
    .nav_header--links ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-left: -10px;
      margin-right: -10px; }
    .nav_header--links li {
      padding: 0.625rem;
      text-align: center; }
    .nav_header--links a {
      padding: 0.5rem 0;
      font-size: 1.375rem;
      font-family: "Libre Baskerville", serif;
      font-weight: 500;
      position: relative; }
      .nav_header--links a:before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: #CF9338;
        position: absolute;
        bottom: 0;
        transform: scaleX(0);
        transition: 0.3s transform; }
      .nav_header--links a:hover, .nav_header--links a.active {
        color: #CF9338; }
        .nav_header--links a:hover:before, .nav_header--links a.active:before {
          transform: scaleX(1); }

.form-group {
  position: relative;
  transition: 0.3s all; }
  .form-group label.error {
    position: absolute;
    bottom: -20px;
    left: 15px; }
  .form-group.error .form-control {
    border: 1px solid rgba(233, 55, 72, 0.25); }
  .form-group .form-control.error {
    border: 1px solid rgba(233, 55, 72, 0.25); }
  .form-group.valid {
    background: url(../../../medias/images/checker-valid.svg) no-repeat;
    background-position: center right;
    padding-right: 1.875rem; }

label.error {
  color: #E93748;
  text-align: left;
  font-size: 0.625rem; }

.custom-control {
  position: relative; }
  .custom-control label.error {
    position: absolute;
    bottom: -20px;
    left: 0; }

.intro-text {
  padding-top: 3rem;
  padding-bottom: 1.5625rem; }
  .intro-text__container {
    max-width: 58.75rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
    @media (min-width: 767px) {
      .intro-text__container {
        display: flex;
        align-items: center; } }
  .intro-text__thumb {
    flex: 0 0 auto;
    max-width: 8.75rem; }
    @media (min-width: 767px) {
      .intro-text__thumb {
        margin-right: 2.1875rem; } }
    @media (max-width: 767.98px) {
      .intro-text__thumb {
        margin-bottom: 0.75rem;
        max-width: 5.3125rem;
        margin-left: auto;
        margin-right: auto; } }
  .intro-text h2, .intro-text .h2 {
    color: #221F20;
    text-align: center;
    margin-bottom: 0.875rem; }
    @media (max-width: 767.98px) {
      .intro-text h2, .intro-text .h2 {
        margin-bottom: 1rem !important; } }

@media (min-width: 767px) {
  .intro-produit {
    padding-top: 5.625rem;
    padding-bottom: 2.8125rem; } }

.s-reference {
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
  padding-top: 3.5rem;
  padding-bottom: 3.375rem;
  background-color: #fff;
  margin-bottom: 3.75rem; }
  @media (max-width: 767.98px) {
    .s-reference {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      padding-top: 1.875rem;
      padding-bottom: 1.25rem;
      margin-bottom: 3.75rem; }
      .s-reference .nav-slick-arrow {
        text-align: center;
        margin-top: 0.9375rem; } }

.reference__intro {
  max-width: 59.0625rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 3.75rem; }
  @media (max-width: 767.98px) {
    .reference__intro {
      margin-bottom: 0.8125rem; } }
  .reference__intro .h2 {
    color: #221F20;
    line-height: 1.44;
    margin-bottom: 0.875rem; }

.slider-reference {
  padding-left: 4.375rem;
  padding-right: 4.375rem; }
  @media (max-width: 767.98px) {
    .slider-reference {
      padding-left: 2.5rem;
      padding-right: 2.5rem; } }
  .slider-reference a {
    outline: none; }
  .slider-reference__item {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 0.625rem;
    outline: none; }
    @media (max-width: 767.98px) {
      .slider-reference__item {
        padding: 0.625rem; } }
  .slider-reference .slick-next {
    right: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .slider-reference .slick-next:before {
      background-image: url(../../../medias/images/left-arrow-black.svg); }
  .slider-reference .slick-prev {
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    .slider-reference .slick-prev:before {
      background-image: url(../../../medias/images/right-arrow-black.svg); }

.container--sliderExpoProduct {
  position: relative;
  padding-top: 2.8125rem;
  padding-bottom: 2.8125rem; }
  @media (max-width: 1199.98px) {
    .container--sliderExpoProduct {
      margin-left: -24px;
      margin-right: -24px;
      padding-bottom: 3.75rem;
      padding-top: 0; } }
  @media (max-width: 359.98px) {
    .container--sliderExpoProduct {
      margin-left: -16px;
      margin-right: -16px; } }
  @media (min-width: 1200px) {
    .container--sliderExpoProduct .sliderExpoProduct--container {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 33%; } }
  @media (min-width: 1200px) {
    .container--sliderExpoProduct .nav-slick-arrow {
      text-align: center;
      margin-top: 6.25rem; } }

@media (max-width: 1199.98px) {
  .sliderExpoProduct--cards .produit-card {
    max-width: 100%;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.16); } }

@media (max-width: 1919.98px) {
  .sliderExpoProductMiel {
    padding-bottom: 2.5rem;
    margin-bottom: 0 !important; }
    .sliderExpoProductMiel .slick-dots {
      bottom: 0.9375rem; }
      .sliderExpoProductMiel .slick-dots li {
        width: 0.9375rem;
        height: 0.9375rem;
        margin: 0 4px; }
        .sliderExpoProductMiel .slick-dots li button {
          width: inherit;
          height: inherit; }
          .sliderExpoProductMiel .slick-dots li button::before {
            width: inherit;
            height: inherit; }
      .sliderExpoProductMiel .slick-dots li:not(.slick-active) button:before {
        background-color: #D3D2D2; } }

.sliderExpoProductMiel__item .produit-card {
  position: absolute;
  display: none; }

@media (min-width: 1200px) {
  .container-carousel-miel .container--sliderExpoProduct {
    padding: 2.5rem 0; }
    .container-carousel-miel .container--sliderExpoProduct:nth-child(odd) .sliderExpoProductMiel {
      padding-left: 25%; }
    .container-carousel-miel .container--sliderExpoProduct:nth-child(even) .sliderExpoProductMiel {
      padding-right: 25%; }
    .container-carousel-miel .container--sliderExpoProduct:nth-child(even) .sliderExpoProduct--container {
      left: auto;
      right: 0; } }

#filter-month {
  max-width: 33.5rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1025; }
  @media (max-width: 1919.98px) {
    #filter-month {
      margin-bottom: 2.25rem; } }
  #filter-month select {
    width: 100%;
    border: 1px solid #707070;
    border-radius: 1.5625rem;
    height: 3.125rem;
    text-align: center; }
    #filter-month select:focus {
      outline: none; }
    #filter-month select option {
      text-align: center; }

@media (min-width: 1200px) {
  .container--sliderExpoProduct {
    padding-left: 30%; } }

.sliderExpoProduct {
  width: 100%;
  position: absolute !important;
  clear: both;
  overflow: hidden; }
  .sliderExpoProduct img {
    visibility: hidden;
    /* hide images until carousel can handle them */
    cursor: pointer;
    /* otherwise it's not as obvious items can be clicked */
    width: 40%;
    height: auto; }

.sliderExpoProduct--container {
  z-index: 50; }

.sliderExpoProduct-dots {
  list-style: none;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block; }
  @media (min-width: 1200px) {
    .sliderExpoProduct-dots {
      display: none; } }
  @media (min-width: 767px) {
    .sliderExpoProduct-dots {
      bottom: 1.25rem; } }
  .sliderExpoProduct-dots li {
    top: 0;
    left: 0;
    width: 1.0625rem;
    height: 1.0625rem;
    background-color: #D3D2D2;
    border-radius: 50%;
    display: inline-block;
    margin: 0 0.25rem;
    cursor: pointer;
    transition: 0.2s all; }
    .sliderExpoProduct-dots li.active {
      background-color: #CF9338; }

.container-bandeau-ruche {
  position: relative;
  text-align: center;
  color: #fff;
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
  font-size: 0.8333333333333333vw; }
  @media (min-width: 1920px) {
    .container-bandeau-ruche {
      font-size: 16px; } }
  .container-bandeau-ruche .container {
    position: relative;
    z-index: 1; }
    @media (max-width: 767.98px) {
      .container-bandeau-ruche .container {
        padding-left: 35px;
        padding-right: 35px; } }
  @media (max-width: 767.98px) {
    .container-bandeau-ruche {
      padding-top: 1.875rem; } }
  .container-bandeau-ruche h2, .container-bandeau-ruche .h2 {
    color: inherit;
    margin-bottom: 1.875rem; }
    @media (max-width: 767.98px) {
      .container-bandeau-ruche h2, .container-bandeau-ruche .h2 {
        font-size: 1.5625rem; } }
  .container-bandeau-ruche:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 81.25em;
    background: #ecb041;
    background: -moz-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 200%);
    background: -webkit-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 200%);
    background: radial-gradient(ellipse at center, #ecb041 0%, #cf9338 200%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ecb041', endColorstr='#cf9338',GradientType=1 ); }
    @media (min-width: 767px) {
      .container-bandeau-ruche:before {
        height: auto;
        top: 0;
        bottom: 12.9375em;
        background: #ecb041;
        background: -moz-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 100%);
        background: -webkit-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 100%);
        background: radial-gradient(ellipse at center, #ecb041 0%, #cf9338 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cf9338', endColorstr='#ecb041',GradientType=1 ); } }
  .container-bandeau-ruche:after {
    content: '';
    position: absolute;
    bottom: 0;
    top: 75em;
    left: 0;
    right: 0;
    background: #ecb041;
    background: -moz-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 360%);
    background: -webkit-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 360%);
    background: radial-gradient(ellipse at center, #ecb041 0%, #cf9338 360%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cf9338', endColorstr='#ecb041',GradientType=1 ); }
    @media (min-width: 767px) {
      .container-bandeau-ruche:after {
        height: 18.75em;
        top: auto;
        background: #ecb041;
        bottom: 0;
        background: -moz-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 260%);
        background: -webkit-radial-gradient(center, ellipse cover, #ecb041 0%, #cf9338 260%);
        background: radial-gradient(ellipse at center, #ecb041 0%, #cf9338 260%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cf9338', endColorstr='#ecb041',GradientType=1 ); } }
  .container-bandeau-ruche img {
    position: relative; }

.libelle {
  position: relative;
  padding-left: 2.375rem; }
  @media (max-width: 767.98px) {
    .libelle {
      margin-bottom: 1.25rem; } }
  .libelle-title {
    line-height: 1.36;
    font-size: 1.5625rem; }
    @media (min-width: 767px) {
      .libelle-title {
        font-size: 2.5625em;
        line-height: 1.15; } }
  .libelle-description {
    margin-top: 0.625em;
    font-size: 1rem; }
    @media (min-width: 767px) {
      .libelle-description {
        line-height: 1.375;
        font-size: 1em; } }
  .libelle-title, .libelle-description {
    color: #fff;
    font-family: "Open Sans", sans-serif; }
    @media (min-width: 767px) {
      .libelle-title, .libelle-description {
        margin-bottom: 0; } }
    @media (max-width: 767.98px) {
      .libelle-title, .libelle-description {
        text-align: left; } }
  .libelle-number {
    display: block;
    width: 1.375rem;
    height: 1.375rem;
    line-height: 1.375rem;
    border-radius: 50%;
    font-size: 1.125rem;
    background-color: #fff;
    color: #221F20;
    text-align: center;
    margin-right: 1rem;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    position: absolute;
    top: 0.3125rem;
    left: 0; }

.ruche__container--imageText--item {
  display: inline-block;
  position: relative;
  transform: scale(1);
  transition: 0.3s transform;
  cursor: pointer; }
  @media (min-width: 767px) {
    .ruche__container--imageText--item:hover {
      transform: scale(1.05); }
      .ruche__container--imageText--item:hover .ruche__container--item--text {
        opacity: 1;
        visibility: visible; }
    .ruche__container--imageText--item.item7:hover {
      transform: scale(1); } }

.ruche__container--item--text {
  position: absolute;
  right: 0;
  transform: translateX(100%);
  text-align: left; }
  @media (min-width: 767px) {
    .ruche__container--item--text {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s all; } }
  .ruche__container--item--text::before {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    background-color: #fff; }
  @media (max-width: 767.98px) {
    .ruche__container--item--text .libelle-description,
    .ruche__container--item--text .libelle-title {
      display: none; } }
  .ruche__container--item--text .libelle-number {
    position: static;
    margin-right: 0; }
  @media (min-width: 767px) {
    .ruche__container--item--text .libelle-number {
      display: none; } }

.ruche__container--position {
  position: relative; }

.ruche img:not(.img10) {
  -webkit-filter: drop-shadow(10px 3px 30px rgba(0, 0, 0, 0.35));
  filter: drop-shadow(10px 3px 30px rgba(0, 0, 0, 0.35)); }
  @media (max-width: 767.98px) {
    .ruche img:not(.img10) {
      -webkit-filter: drop-shadow(5px 3px 8px rgba(0, 0, 0, 0.35));
      filter: drop-shadow(5px 3px 8px rgba(0, 0, 0, 0.35)); } }

.ruche .item1 {
  width: 36.1875em;
  max-width: 579px; }
  .ruche .item1 .ruche__container--item--text {
    top: 4.0625em;
    transform: translateX(100%);
    padding-left: 5.625em;
    text-align: left; }
    @media (max-width: 767.98px) {
      .ruche .item1 .ruche__container--item--text {
        top: 3.125em;
        padding-left: 4.375em; } }
    .ruche .item1 .ruche__container--item--text::before {
      top: 2.1875em;
      left: 0;
      width: 4.375em; }
      @media (max-width: 767.98px) {
        .ruche .item1 .ruche__container--item--text::before {
          top: 2.5em; } }

.ruche .item2 {
  width: 34.0625em;
  max-width: 545px;
  margin-top: -1.875em;
  position: relative;
  z-index: 5; }
  .ruche .item2 .ruche__container--item--text {
    top: 0.3125em;
    transform: translateX(100%);
    padding-left: 10em;
    text-align: left; }
    @media (max-width: 767.98px) {
      .ruche .item2 .ruche__container--item--text {
        padding-left: 16.25em; } }
    .ruche .item2 .ruche__container--item--text::before {
      top: 2.1875em;
      left: 0;
      width: 8.75em; }
      @media (max-width: 767.98px) {
        .ruche .item2 .ruche__container--item--text::before {
          width: 16.25em; } }

.ruche .item3 {
  width: 34.0625em;
  max-width: 545px;
  margin-top: -0.5em;
  position: relative;
  z-index: 3; }
  @media (min-width: 767px) {
    .ruche .item3:hover .img10 {
      opacity: 1;
      visibility: visible; }
    .ruche .item3 .img10 {
      opacity: 0;
      visibility: hidden;
      transition: 0.3s opacity; } }

.ruche .item4 {
  width: 34.0625em;
  max-width: 545px;
  margin-top: 0.5em;
  position: relative;
  z-index: 4; }
  .ruche .item4 .ruche__container--item--text {
    top: -0.5em;
    transform: translateX(-100%);
    padding-right: 7.5em;
    text-align: right;
    z-index: 10;
    left: 0; }
    @media (max-width: 767.98px) {
      .ruche .item4 .ruche__container--item--text {
        padding-right: 5.3125em; } }
    .ruche .item4 .ruche__container--item--text::before {
      top: 1.375em;
      right: 0;
      width: 5.625em; }
  @media (max-width: 767.98px) {
    .ruche .item4 .libelle-number {
      margin-left: auto; } }

.ruche .item5 {
  width: 34.0625em;
  max-width: 545px;
  position: relative;
  z-index: 2;
  margin-top: -4em; }
  .ruche .item5 .ruche__container--item--text {
    top: -0.4375em;
    transform: translateX(100%);
    padding-left: 3.125em;
    text-align: left; }
    @media (max-width: 767.98px) {
      .ruche .item5 .ruche__container--item--text {
        padding-left: 4.375em; } }
    .ruche .item5 .ruche__container--item--text::before {
      top: 1.75em;
      left: -6.25em;
      width: 8.75em; }
    .ruche .item5 .ruche__container--item--text.text2 {
      top: 42%;
      padding-left: 12.5em;
      transform: translateX(100%);
      padding-left: 10em;
      text-align: left; }
      @media (max-width: 767.98px) {
        .ruche .item5 .ruche__container--item--text.text2 {
          padding-left: 16.25em; } }
      .ruche .item5 .ruche__container--item--text.text2::before {
        top: 2.1875em;
        left: 0;
        width: 8.75em; }
        @media (max-width: 767.98px) {
          .ruche .item5 .ruche__container--item--text.text2::before {
            width: 16.25em; } }

.ruche .item6 {
  width: 39.8125em;
  max-width: 637px;
  position: relative;
  z-index: 1;
  margin-top: -7.5em;
  margin-left: -5%; }
  @media (max-width: 767.98px) {
    .ruche .item6 .libelle-number {
      margin-left: auto; } }
  .ruche .item6 .ruche__container--item--text {
    top: 4.375em;
    transform: translateX(-100%);
    padding-right: 12.5em;
    text-align: right;
    z-index: 10;
    left: 8.75em;
    width: 56.25rem; }
    @media (max-width: 767.98px) {
      .ruche .item6 .ruche__container--item--text {
        padding-right: 11.25em; } }
    .ruche .item6 .ruche__container--item--text::before {
      top: 1.875em;
      right: -3.125em;
      width: 15em; }

.ruche .item7 {
  width: 15em;
  max-width: 240px;
  position: absolute;
  top: 30%;
  left: -40%;
  z-index: 11; }
  .ruche .item7 .ruche__container--item--text {
    top: 85%;
    transform: translateX(-100%);
    text-align: right;
    z-index: 11; }
    @media (min-width: 767px) {
      .ruche .item7 .ruche__container--item--text {
        width: 23.125em;
        padding-right: 10em;
        right: -7.5em; } }
    @media (max-width: 767.98px) {
      .ruche .item7 .ruche__container--item--text {
        right: 2.1875em; }
        .ruche .item7 .ruche__container--item--text .libelle-number {
          margin-top: 0.875em;
          margin-left: -0.5em; } }
    .ruche .item7 .ruche__container--item--text::before {
      top: 2.1875em;
      right: 3.125em;
      width: 5.625em; }
      @media (max-width: 767.98px) {
        .ruche .item7 .ruche__container--item--text::before {
          width: 1px;
          height: 3.75em;
          top: 0;
          right: 1.375em; } }
  .ruche .item7 div:not(.ruche__container--item--text) {
    position: relative; }
    .ruche .item7 div:not(.ruche__container--item--text) .img10 {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translate(-50%, 50%);
      width: 8.1875em; }

.slick-prev-title {
  font-size: 2.1875rem;
  line-height: 1.34;
  margin-bottom: 0;
  margin-left: 1.3125rem;
  margin-right: 1.3125rem;
  color: #221F20; }
  @media (max-width: 767.98px) {
    .slick-prev-title {
      font-size: 1.25rem;
      margin-left: 0.625rem;
      margin-right: 0.625rem; } }

.slick-arrow {
  position: static;
  display: inline-block; }

.container-nos-valeur {
  margin-top: 3.125rem;
  margin-bottom: 7.375rem; }
  @media (max-width: 767.98px) {
    .container-nos-valeur {
      margin-bottom: 4.0625rem;
      padding-left: 0;
      padding-right: 0; } }
  .container-nos-valeur .h1 {
    text-align: center;
    color: #CF9338;
    line-height: 1.36;
    margin-bottom: 2.1875rem; }
    @media (max-width: 767.98px) {
      .container-nos-valeur .h1 {
        margin-bottom: 0.625rem; } }
  .container-nos-valeur .nav-slick-arrow {
    position: relative; }
    @media (max-width: 767.98px) {
      .container-nos-valeur .nav-slick-arrow {
        display: flex;
        align-items: center;
        margin-top: 0.625rem; } }
    @media (max-width: 359.98px) {
      .container-nos-valeur .nav-slick-arrow {
        padding-left: 3%;
        padding-right: 3%; } }

@media (max-width: 767.98px) {
  .row-nos-valeur {
    margin-left: 0;
    margin-right: 0; } }

@media (min-width: 767px) {
  .row-nos-valeur {
    margin-left: -30px;
    margin-right: -30px; }
    .row-nos-valeur img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      padding-right: 1px; }
    .row-nos-valeur .col-left,
    .row-nos-valeur .col-right {
      padding-left: 30px;
      padding-right: 30px;
      width: 100%; } }

.row-nos-valeur .col-right {
  position: relative; }
  @media (min-width: 1200px) {
    .row-nos-valeur .col-right {
      padding-bottom: 50px;
      width: 30%;
      max-width: 30%;
      flex: 0 0 30%;
      order: 1; } }
  @media (max-width: 767.98px) {
    .row-nos-valeur .col-right {
      padding-left: 2.3125rem;
      padding-right: 2.3125rem; } }
  @media (max-width: 359.98px) {
    .row-nos-valeur .col-right {
      padding-left: 3%;
      padding-right: 3%; } }
  @media (min-width: 1200px) {
    .row-nos-valeur .col-right .nav-slick-arrow {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center; } }

@media (max-width: 767.98px) {
  .row-nos-valeur .col-left {
    padding-left: 0;
    padding-right: 0; } }

@media (min-width: 1200px) {
  .row-nos-valeur .col-left {
    width: 70%;
    max-width: 70%;
    flex: 0 0 70%;
    order: 2; } }

.row-nos-valeur .number {
  font-size: 7.5rem;
  font-family: "Libre Baskerville", serif; }
  @media (max-width: 767.98px) {
    .row-nos-valeur .number {
      font-size: 4.375rem; } }
  @media (min-width: 767px) and (max-width: 1919.98px) {
    .row-nos-valeur .number {
      font-size: 8vw; } }

.row-nos-valeur h3, .row-nos-valeur .h3 {
  color: #CF9338;
  line-height: 1.125;
  margin-bottom: 1.125rem;
  word-wrap: break-word;
  overflow-wrap: break-word; }
  @media (max-width: 767.98px) {
    .row-nos-valeur h3, .row-nos-valeur .h3 {
      margin-bottom: 0.5rem; } }

.row-nos-valeur .p {
  color: #6D6E71;
  line-height: 1.375; }
  @media (min-width: 1200px) {
    .row-nos-valeur .p {
      max-width: 325px; } }

.sliderNumber-byOne .slick-track {
  display: flex; }
  .sliderNumber-byOne .slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center; }
    .sliderNumber-byOne .slick-track .slick-slide > div {
      width: 100%; }

.container-territoire {
  margin-bottom: 7.9375rem; }
  @media (max-width: 767.98px) {
    .container-territoire {
      margin-bottom: 4.0625rem; } }
  .container-territoire .h1 {
    color: #CF9338;
    text-align: center;
    margin-bottom: 3.1875rem; }
    @media (max-width: 767.98px) {
      .container-territoire .h1 {
        margin-bottom: 1.125rem; } }
  @media (min-width: 767px) {
    .container-territoire .pos-a-container {
      bottom: -2.5rem; } }
  @media (max-width: 767.98px) {
    .container-territoire .pos-a-container {
      padding-left: 0;
      padding-right: 0;
      position: static; } }

.territoire__body {
  position: relative; }
  .territoire__body img {
    width: 100%; }

@media (min-width: 767px) {
  .territoire__card {
    margin-left: auto; } }

@media (max-width: 767.98px) {
  .territoire__card {
    max-width: 100%; } }

.territoire__card h3 {
  color: #CF9338;
  margin-bottom: 1.4375rem; }

.bandeau__body {
  position: relative; }

.scroll-to-next {
  height: 62px;
  width: 43px;
  border: 4px solid #221F20;
  display: block;
  border-radius: 20px;
  position: absolute;
  bottom: 0;
  right: 24px; }
  .scroll-to-next::before {
    content: '';
    position: absolute;
    width: 4px;
    height: 11px;
    background-color: #221F20;
    top: 17px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px; }

.container-suivre-actualites {
  padding-left: 2.625rem;
  padding-right: 2.625rem;
  max-width: 90.5rem;
  margin-bottom: 1.875rem; }
  @media (max-width: 1199.98px) {
    .container-suivre-actualites {
      padding-left: 24px;
      padding-right: 24px; } }
  @media (max-width: 767.98px) {
    .container-suivre-actualites {
      padding-left: 35px;
      padding-right: 35px; } }
  @media (max-width: 359.98px) {
    .container-suivre-actualites {
      padding-left: 16px;
      padding-right: 16px; } }
  .container-suivre-actualites .h1 {
    color: #CF9338;
    text-align: center;
    margin-bottom: 3.25rem; }
    @media (max-width: 767.98px) {
      .container-suivre-actualites .h1 {
        margin-bottom: 0.625rem; } }
  .container-suivre-actualites .row {
    margin-left: -42px;
    margin-right: -42px; }
    @media (max-width: 1199.98px) {
      .container-suivre-actualites .row {
        margin-left: -24px;
        margin-right: -24px; } }
    @media (max-width: 767.98px) {
      .container-suivre-actualites .row {
        margin-left: -35px;
        margin-right: -35px; } }
    @media (max-width: 359.98px) {
      .container-suivre-actualites .row {
        margin-left: -16px;
        margin-right: -16px; } }
    .container-suivre-actualites .row [class*="col-"] {
      padding-left: 42px;
      padding-right: 42px; }
      @media (max-width: 1199.98px) {
        .container-suivre-actualites .row [class*="col-"] {
          padding-left: 24px;
          padding-right: 24px; } }
      @media (max-width: 767.98px) {
        .container-suivre-actualites .row [class*="col-"] {
          padding-left: 35px;
          padding-right: 35px; } }
      @media (max-width: 359.98px) {
        .container-suivre-actualites .row [class*="col-"] {
          padding-left: 16px;
          padding-right: 16px; } }

.actualites-social-network {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 4.375rem; }
  @media (min-width: 768px) {
    .actualites-social-network {
      margin-left: -10px;
      margin-right: -10px; } }
  @media (max-width: 767.98px) {
    .actualites-social-network {
      flex-direction: column;
      margin-bottom: 0.9375rem; } }
  .actualites-social-network li {
    margin: 0.625rem 1.875rem; }
    @media (max-width: 1199.98px) {
      .actualites-social-network li {
        margin: 0.625rem; } }
    @media (max-width: 767.98px) {
      .actualites-social-network li {
        margin: 0.5rem 0;
        width: 100%; } }
  .actualites-social-network a {
    border: 1px solid #D3D2D2;
    color: #CF9338;
    font-size: 1.25rem;
    font-weight: 700;
    height: 4.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.625rem 1.875rem;
    vertical-align: middle;
    transition: 0.3s all;
    position: relative; }
    @media (max-width: 767.98px) {
      .actualites-social-network a {
        padding: 0.625rem 0.3125rem 0.625rem 3.125rem;
        font-size: 0.9375rem;
        height: 3.125rem; } }
    @media (min-width: 767px) {
      .actualites-social-network a:before {
        margin-right: 1.875rem; } }
    @media (max-width: 767.98px) {
      .actualites-social-network a:before {
        position: absolute;
        left: 0.9375rem; } }
    .actualites-social-network a:hover {
      color: #221F20; }

.icon-facebook:before {
  content: '';
  width: 18px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  background: url(../../../medias/images/Facebook.svg) no-repeat;
  background-size: 18px 35px; }
  @media (max-width: 767.98px) {
    .icon-facebook:before {
      width: 28px;
      height: 28px;
      background-size: 15px 28px; } }

.icon-facebook:hover:before {
  background-image: url(../../../medias/images/Facebook-black.svg); }

.icon-instagram:before {
  content: '';
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  background: url(../../../medias/images/Instagram.svg) no-repeat;
  background-size: 35px 35px; }
  @media (max-width: 767.98px) {
    .icon-instagram:before {
      width: 28px;
      height: 28px;
      background-size: 28px 28px; } }

.icon-instagram:hover:before {
  background-image: url(../../../medias/images/Instagram-black.svg); }

.icon-google:before {
  content: '';
  width: 35px;
  height: 35px;
  display: inline-block;
  vertical-align: middle;
  background: url(../../../medias/images/google.svg) no-repeat;
  background-size: 35px 35px; }
  @media (max-width: 767.98px) {
    .icon-google:before {
      width: 28px;
      height: 28px;
      background-size: 28px 26px; } }

.icon-google:hover:before {
  background-image: url(../../../medias/images/google-black.svg); }

.b-actualite {
  margin-bottom: 3.5rem; }
  .b-actualite__thumb {
    margin-bottom: 1.5rem; }
    .b-actualite__thumb img {
      width: 100%; }
    @media (max-width: 767.98px) {
      .b-actualite__thumb {
        margin-bottom: 1.3125rem;
        margin-left: -35px;
        margin-right: -35px; } }
    @media (max-width: 359.98px) {
      .b-actualite__thumb {
        margin-left: -16px;
        margin-right: -16px; } }
  .b-actualite h3 {
    line-height: 1.84;
    margin-bottom: 0.625rem;
    color: #CF9338; }
    @media (max-width: 767.98px) {
      .b-actualite h3 {
        line-height: 1.16; } }
  .b-actualite .p {
    margin-bottom: 1.25rem; }
  .b-actualite .btn {
    margin-top: 1.25rem; }
    @media (min-width: 767px) {
      .b-actualite .btn {
        float: right; } }
    @media (max-width: 767.98px) {
      .b-actualite .btn {
        max-width: 220px;
        display: block;
        margin-left: auto;
        margin-right: auto; } }

@media (min-width: 767px) {
  .container-profond .b-profond:nth-child(even) .b-profond__container {
    margin-left: auto;
    text-align: right; }
    .container-profond .b-profond:nth-child(even) .b-profond__container .iso {
      margin-left: auto; }
  .container-profond .b-profond:nth-child(odd) .b-profond__body {
    order: 1; }
  .container-profond .b-profond:nth-child(odd) .b-profond__thumb {
    order: 2; } }

@media (min-width: 1200px) {
  .container-profond.container-padding-60 .b-profond:nth-child(even) .b-profond__body {
    padding-left: 3.75rem; }
  .container-profond.container-padding-60 .b-profond:nth-child(even) .b-profond__thumb {
    padding-right: 3.75rem; }
  .container-profond.container-padding-60 .b-profond:nth-child(odd) .b-profond__body {
    padding-right: 3.75rem; }
  .container-profond.container-padding-60 .b-profond:nth-child(odd) .b-profond__thumb {
    padding-left: 3.75rem; } }

@media (max-width: 767.98px) {
  .container-profond.container-padding-60 {
    padding-bottom: 0; } }

.b-profond {
  margin-bottom: 3.5rem; }
  .b-profond.b-profond-produit {
    padding-top: 1.25rem; }
    @media (min-width: 767px) {
      .b-profond.b-profond-produit {
        padding-top: 2.5rem; } }
  @media (min-width: 767px) {
    .b-profond {
      margin-bottom: 2.5rem; } }
  .b-profond .iso {
    display: block;
    margin-bottom: 1.25rem; }
    @media (max-width: 767.98px) {
      .b-profond .iso {
        margin-bottom: 0.625rem; } }
  .b-profond .h5 {
    margin-top: 3.75rem;
    margin-bottom: 1rem; }
    @media (max-width: 767.98px) {
      .b-profond .h5 {
        margin-top: 1.25rem; } }
  @media (max-width: 767.98px) {
    .b-profond__thumb {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 1.25rem; } }
  .b-profond__thumb .cover {
    width: 100%;
    height: 100%; }
    @media (min-width: 767px) {
      .b-profond__thumb .cover.h-50-sm-up {
        height: 50%; } }
    @media (min-width: 767px) {
      .b-profond__thumb .cover:not(:first-child) {
        margin-top: 2.5rem; } }
  @media (min-width: 767px) and (min-width: 767px) {
    .b-profond__thumb .cover:not(:first-child).h-50-sm-up {
      height: calc(50% - 2.5rem); } }
  .b-profond__body {
    align-self: center; }
    @media (max-width: 767.98px) {
      .b-profond__body {
        padding-left: 2.3125rem;
        padding-right: 2.3125rem; } }
    @media (max-width: 359.98px) {
      .b-profond__body {
        padding-left: 3%;
        padding-right: 3%; } }
  .b-profond h2 {
    margin-bottom: 0.5rem;
    word-break: break-word;
    white-space: initial;
    text-overflow: inherit;
    word-wrap: break-word; }
    @media (min-width: 767px) {
      .b-profond h2 {
        margin-bottom: 1.5625rem; } }
  @media (max-width: 767.98px) {
    .b-profond__container {
      max-width: none; } }
  .b-profond .btn {
    margin-top: 1.25rem; }
    @media (min-width: 767px) {
      .b-profond .btn {
        float: right; } }
    @media (max-width: 767.98px) {
      .b-profond .btn {
        margin-left: auto;
        margin-right: auto;
        max-width: 13.75rem;
        display: block; } }

@media (min-width: 767px) {
  .fadeUp__animated {
    opacity: 0;
    transform: translate3d(0, 300px, 0);
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, color 0.3s ease, background 0.3s ease; }
  .fadeUp.is-animated .fadeUp__animated {
    opacity: 1;
    transform: translate3d(0, 0, 0); } }
