

.back-link {
    color: $gray-700;
    vertical-align: middle;
    &:before {
        content: '';
        display: inline-block;
        width: rem(10);
        height: rem(14);
        background: url(#{$image}back-link.svg) no-repeat;
        background-size: rem(10) rem(14);
        vertical-align: middle;
        margin-right: rem(16);
        transform: translateX(0);
        transition: 0.3s transform;
        @include media-breakpoint-between(sm, xgl) {
            width: rem(10);
            height: rem(14);
            background-size: rem(10) rem(14);
            margin-right: rem(16);
            
        }
    }
    &:hover {
        &:before {
            background-image: url(#{$image}back-link-orange.svg);
            transform: translateX(-5px);
        }
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
    
}

.nav_header {
    padding-top: rem(60);
    padding-bottom: rem(50);
    
    @include media-breakpoint-down(sm) {
        padding-top: rem(20);
        padding-bottom: rem(20);
    }
    &--top {
        @include media-breakpoint-up(xgl) {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            position: relative;
            &.padding-x-320 {
                padding-left: rem(320);
                padding-right: rem(320);        
            }
                
            .back-link {
                left: 0;
            }
            .btn {
                right: 0;
            }
            .btn,
            .back-link {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    h1, .h1 {
        color: $orange;
        text-align: center;
        margin-bottom: 0;
    }
    .btn {
        background-color: $orange;
        color: $white;
        &:hover {
            background-color: $black;
            border-color: $black;
        }
        &:before {
            background-image: url(#{$image}bee-btn-icon-white.svg);
        }
    }

    &--links {
        margin-top: rem(30);
        ul {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-left: -10px;
            margin-right: -10px;
        }
        li {
            padding: rem(10);
            text-align: center;
        }
        a {
            padding: rem(8) 0;
            font-size: rem(22);
            // font-size: rem(18);
            font-family: $fontTitle;
            font-weight: 500;
            position: relative;
            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                background-color: $orange;
                position: absolute;
                bottom: 0;
                transform: scaleX(0);
                transition: 0.3s transform;
            }
            &:hover,
            &.active {
                color: $orange;
                &:before {
                    transform: scaleX(1);
                }
            }
        }
    }
}

