.container-profond {

    @include media-breakpoint-up(sm) {
        .b-profond:nth-child(even) {
            .b-profond__container {
                margin-left: auto;
                text-align: right;
                .iso {
                    margin-left: auto;
                }
            }
        }
        
        .b-profond:nth-child(odd) {
            .b-profond__body {
                order: 1;
            }
            .b-profond__thumb {
                order: 2;
            }
        }
    }
   &.container-padding-60 {
        @include media-breakpoint-up(xgl) {
            .b-profond:nth-child(even) {
                .b-profond__body {
                    padding-left: rem(60);
                }
                .b-profond__thumb {
                    padding-right: rem(60);
                }
            }
            .b-profond:nth-child(odd) {
                .b-profond__body {
                    padding-right: rem(60);
                }
                .b-profond__thumb {
                    padding-left: rem(60);
                }
            }
        }
        @include media-breakpoint-down(sm) {
            padding-bottom: 0;
        }
    }
}



.b-profond {
    margin-bottom: rem(56);
    &.b-profond-produit {
        padding-top: rem(20);
        @include media-breakpoint-up(sm) {
            // align-items: center;
            padding-top: rem(40);
        }
    }
    @include media-breakpoint-up(sm) {
        margin-bottom: rem(40);
    }
    .iso {
        display: block;
        margin-bottom: rem(20);
        @include media-breakpoint-down(sm) {
            margin-bottom: rem(10);
        }
    }
    
    .h5 {
        margin-top: rem(60);
        margin-bottom: 1rem;
        @include media-breakpoint-down(sm) {
            margin-top: rem(20);
        }
    }
    &__thumb {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
            margin-bottom: rem(20);
        }
        .cover  {
            width: 100%;
            height: 100%;
            &.h-50-sm-up {
                @include media-breakpoint-up(sm) {
                    height: 50%;
                }
            }
            @include media-breakpoint-up(sm) {
                
                &:not(:first-child) {
                    margin-top: rem(40);
                    &.h-50-sm-up {
                        @include media-breakpoint-up(sm) {
                            height: calc(50% - 2.5rem);
                        }
                    }
                }

            }
        }
    }
    &__body {
        align-self: center;
        @include media-breakpoint-down(sm) {
            padding-left: rem(37);
            padding-right: rem(37);
            
        }
        @include media-breakpoint-down(xs) {
			padding-left: 3%;
			padding-right: 3%;
        }
        
    }
    h2 {
        margin-bottom: rem(8);
        word-break: break-word;
        white-space: initial;
        text-overflow: inherit;
        word-wrap: break-word;
        @include media-breakpoint-up(sm) {
            margin-bottom: rem(25);
        }
    }
    &__container {
        @include media-breakpoint-down(sm) {
            max-width: none;
        }
    }
    .btn {
        margin-top: rem(20);
        @include media-breakpoint-up(sm) {
            float: right;
        }
        @include media-breakpoint-down(sm) {
            margin-left: auto;
            margin-right: auto;
            max-width: rem(220);
            display: block;
        }
        
    }
}


@include media-breakpoint-up(sm) {
    .fadeUp {
        &__animated {
            opacity: 0;
            transform: translate3d(0, 300px, 0);
            transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out, color 0.3s ease, background 0.3s ease;
        }
        
        &.is-animated {
            .fadeUp__animated {
                opacity: 1;
                transform: translate3d(0, 0, 0);
            }
        }
    }
}