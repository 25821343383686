.s-search {
	background-color: $white;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
    z-index: 1050;
    display: table;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    padding-top: 130px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    @include media-breakpoint-down(sm) {
        padding-top: rem(10);
        .navbarHeader--logo {
            margin-bottom: rem(28);
        }
    }
    &.is-opened {
        visibility: visible;
        opacity: 1;
    }
    .container {
        max-width: rem(1370);
        @include media-breakpoint-down(sm) {
            padding-left: 35px;
            padding-right: 35px;
        }
        @include media-breakpoint-down(xs) {
            padding-left: 3%;
            padding-right: 3%;
        }
    }
}

[id*="form-search"] {
    .form-group {
        display: flex;
        align-items: center;
        @include media-breakpoint-down(xgl) {
            margin-top: rem(30);
            margin-bottom: rem(15);
        }
        label.error {
            left: 0;
        }
    }
    .form-control {
        padding-top: rem(13);
        padding-bottom: rem(13);
        padding-left: rem(94);
        padding-right: rem(16);
        font-size: rem(18);
        background: url(#{$image}Search-grey.svg) no-repeat center left rem(36);
        color: $black;
        @include media-breakpoint-down(xgl) {
            text-align: center;
            padding: rem(8) rem(10) rem(8) rem(40);
            height: rem(40);
            font-size: rem(15);
            background: url(#{$image}Search-grey.svg) no-repeat center left rem(11);
            background-size: rem(17);
            
            &:focus {
                text-align: left;
            }

        }
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            background: url(#{$image}remove-search.svg) no-repeat;
            background-size: cover;
            height: rem(25);
            width: rem(25);
            @include media-breakpoint-down(xgl) {
                height: rem(20);
                width: rem(20);
            }
        }
    }
    .reset-search {
        color: $orange;
        margin-left: rem(17);
        display: none;
        &.is-show {
            display: block;
        }
        @include media-breakpoint-down(xgl) {
            margin-left: rem(9);
            font-size: rem(15);
        }
    }
}




.close-search {
	width: rem(40);
	height: rem(40);
	position: absolute;
	background: url(#{$image}icon-close.svg) no-repeat;
	background-position: center;
	background-size: rem(31) rem(31);
	top: rem(28);
    right: rem(38);
    @include media-breakpoint-down(xgl) {
        width: rem(24);
        height: rem(40);
        background-size: rem(18) rem(18);
        top: 2px;
        right: auto;
        left: 0;
    }
}