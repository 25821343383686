.container-slider-home {
    @include media-breakpoint-up(sm) {
        margin-top: rem(34);
    }
    @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
        .row-slider-home {
            margin-left: 0;
            margin-right: 0;
        }
    }
}



.sliderDots-byOne {
    &.slick-dotted.slick-slider {
        margin-bottom: 0;
    }
    .slick-dots {
        @include media-breakpoint-up(sm) {
            text-align: left;
            left: rem(25);
        }
        @include media-breakpoint-down(sm) {
            bottom: rem(9);
        }
        li button:before {
            @include media-breakpoint-down(sm) {
                margin: 0 rem(5);
            }
            
            @include media-breakpoint-down(sm) {
                width: rem(17);
                height: rem(17);
            }
        }
        
    }
    &--item {
        position: relative;
        display: block !important;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        .p {
            font-size: 3.4375rem;
            font-family: $fontTitle;
            line-height: 1.27;
            color: black;
            background-color: rgba(255, 255, 255, 0.62);
            max-width: rem(485);
            padding: rem(10) rem(20);
            position: absolute;
            bottom: rem(25);
            right: rem(25);
            text-align: center;
            margin-bottom: 0;
            @include media-breakpoint-between(sm, xgl) {
                font-size: vw(40);
            }
            @include media-breakpoint-down(sm) {
                font-size: rem(18);
                bottom: rem(36);
                padding: rem(5) rem(10);
                left: rem(25);
            }
        }
    }
}


.row-slider-home {
    height: 100%;
    .fadeUp  {
        height: 100%;
    }
    .col-left {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
        @include media-breakpoint-up(xgl) {
            width: 70%;
            max-width: 70%;
            flex: 0 0 70%;

        }
    }
    .col-right {
        max-width: 100%;
        width: 100%;
        flex: 0 0 100%;
        @include media-breakpoint-up(xgl) {
            width: 30%;
            max-width: 30%;
            flex: 0 0 30%;
        }
        @include media-breakpoint-between(mdg, lg) {
            display: flex;
            .b-produit {
                margin-bottom: 0;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
        @include media-breakpoint-between(md, mdg) {
            .b-produit {
                flex-wrap: wrap;
            }
        }


    }
    .sliderDots-byOne {
        height: 100%;
        .slick-list, .slick-slide > div, .slick-track, .sliderDots-byOne--item {
            height: 100%;
        }
    }
}



@include media-breakpoint-up(sm) {
    .row-slider-home {
        margin-left: -10px;
        margin-right: -10px;
        .col-left,
        .col-right {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}