.container-contact {
    max-width: calc(1100px + 10%);
    padding-bottom: rem(64);
    padding-left: rem(40);
    padding-right: rem(40);
    @include media-breakpoint-down(sm) {
        padding-bottom: rem(37);
    }
    @include media-breakpoint-down(sm) {
        .nav-pills {
            display: flex;
            flex-wrap: wrap;
            margin-left: rem(-20);
            margin-right: rem(-20);
            .nav-item {
                max-width: 285px;
                margin-left: rem(20);
                margin-right: rem(20);
            }
        }
        padding-bottom: rem(37);
    }
    .form-control:not(textarea) {
        height: rem(50);
    }
    .p {
        margin-bottom: rem(30);
        @include media-breakpoint-up(sm) {
            text-align: center;
        }
        
    }
    .row {
        margin-left: rem(-15);
        margin-right: rem(-15);
    }
    [class*="col-"] {
        padding-left: rem(15);
        padding-right: rem(15);
    }
    .btn {
        width: rem(220);
        background-color: $orange;
        color: $white;
        border: 0 none;
        &:before {
            background-image: url(#{$image}bee-btn-icon-white.svg);
        }
        &:hover {
            background-color: $black;
        }
        @include media-breakpoint-down(sm) {
            margin-top: rem(22);
        }
    }
    .d-flex {
        align-items: center;
    }
    .nav-link {
        @include media-breakpoint-down(sm) {
            font-family: $fontBody;
        }
    }
}

.custom-control-label {
    font-size: rem(12);
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url(#{$image}case-cocher.svg);
}
